import { isEmpty } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Banner from '../../assets/images/banner.png'
import Logo from '../../assets/images/logo-transparent.png'
import { ErrorAlert } from '../../components/Alerts'
import { Input } from '../../components/FormComponents'
import LoadingOverlay from '../../components/LoadingOverlay'
import { signin } from '../../redux/actions/userActions'
import { validation } from '../../services/validation/signInForm'

export const SignIn = () => {
  let errors = {}
  const [submitted, setSubmitted] = useState(false)
  const [inputs, setInputs] = useState({
    email: '',
    password: ''
  })

  const userSignin = useSelector((state) => state.userSignin)
  const { loading, error } = userSignin

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const setVal = (value, name) => {
    setInputs({ ...inputs, [name]: value })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)
    errors = validation(inputs)
    if (!isEmpty(errors)) {
      return
    } else dispatch(signin(inputs))
  }

  if (submitted) {
    errors = validation(inputs)
  }

  return (
    <div className='flex flex-col gap-5'>
      <div className='absolute top-0 left-0 mt-5 ml-5'>
        <img src={Logo} alt='logo' className='w-1/4' />
      </div>
      <div className='mx-auto w-full max-w-lg'>
        <h2 className='text-center text-3xl font-bold tracking-tight text-gray-900'>
          {t('SIGN_IN.SIGN_IN_HEAD')}
        </h2>
      </div>
      <div className='mx-auto w-full max-w-lg'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 relative'>
          {loading && <LoadingOverlay />}
          {error && <ErrorAlert message={t(error)} />}
          <form className='space-y-6' onSubmit={onSubmit}>
            <Input
              value={inputs['email']}
              type='email'
              onChange={setVal}
              error={errors['email']}
              name='email'
              label={t('SIGN_IN.EMAIL')}
            />
            <Input
              value={inputs['password']}
              type='password'
              onChange={setVal}
              error={errors['password']}
              name='password'
              label={t('SIGN_IN.PASSWORD')}
            />
            <div>
              <button
                type='submit'
                onClick={onSubmit}
                className='flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              >
                {t('SIGN_IN.SIGN_IN')}
              </button>
            </div>
            <div className='flex flex-col justify-center items-center'>
              <img src={Banner} alt='banner' className='w-full' />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SignIn

import { XCircleIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { ErrorAlert, SuccessAlert } from '../../../../components/Alerts'
import {
  Input,
  SquareBtnColored,
  SquareBtnTransparent,
  TagsSelectInput,
  TextArea,
  Toggle
} from '../../../../components/FormComponents'
import LoadingOverlay from '../../../../components/LoadingOverlay'
import Modal from '../../../../components/Modal'
import { resetPoll, submitCreatedPoll } from '../../../../redux/actions/pollActions'
import { getAll } from '../../../../redux/actions/tagsActions'
import { RESET_POLL_DATA } from '../../../../redux/constants/pollConstants'
import { questionValidation, validation } from '../../../../services/validation/createPollForm'

export const CreatePollForm = () => {
  const { t } = useTranslation()
  const [inputs, setInputs] = useState({
    name: '',
    description: '',
    instructions: '',
    tags: [],
    tagName: '',
    questions: []
  })
  const [submitted, setIsSubmitted] = useState(false)
  const dispatch = useDispatch()
  const pollCreated = useSelector((state) => state.isCreated)
  let { loading, created, error } = pollCreated
  const { questions } = inputs
  const [questionErr, setQuestionErr] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [formIsValid, setFormIsValid] = useState(false)
  const [canceled, setIsCanceled] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(resetPoll(RESET_POLL_DATA))
    dispatch(getAll(''))
    return () => {
      dispatch(resetPoll(RESET_POLL_DATA))
    }
  }, [formIsValid, submitted])

  const setVal = (value, name) => {
    switch (name) {
      case 'tags':
        setInputs({ ...inputs, tagName: '', [name]: value })
        break
      case 'tagName':
        dispatch(getAll(value))
        setInputs({ ...inputs, [name]: value })
        break
      default:
        setInputs({ ...inputs, [name]: value })
        break
    }
    setFormErrors(Object.fromEntries(Object.entries(formErrors).filter(([key]) => key !== name)))
  }

  const setTitleValue = (id, value) => {
    questions.map((q) => {
      if (q.id === id) {
        q.title = value
      }
    })
    checkValue(id, 'title')
    setInputs({ ...inputs, questions: questions })
  }

  const saveQuestion = (id, value, e) => {
    e.preventDefault()
    if (value !== '') {
      questions.map((q) => {
        if (q.id === id) {
          q.answers.push({ id: uuidv4(), answer: value })
          q.question = ''
        }
      })
      checkValue(id, 'answers')
    }
  }
  const setQuest = (id, value) => {
    questions.map((q) => {
      if (q.id === id) {
        q.question = value
      }
    })
    setInputs({ ...inputs, questions: questions })
  }

  const appendQuestions = () => {
    let questionObj = {
      id: new Date().getTime(),
      title: '',
      question: '',
      answerType: 'radio',
      answers: [],
      required: true,
      errors: []
    }
    questions.push(questionObj)
    setInputs({ ...inputs, questions: questions })
    setQuestionErr(false)
  }

  const setIsRequired = (id) => {
    let arr = questions.map((q) => {
      if (q.id === id) {
        q.required = !q.required
        return q
      } else {
        return q
      }
    })
    setInputs({ ...inputs, questions: arr })
  }

  const removeQuestion = (id) => {
    let arr = questions.filter((q, index) => q.id !== id)
    setInputs({ ...inputs, questions: arr })
  }

  const removeAnswer = (id, i) => {
    questions.map((q) => {
      if (q.id === id) {
        q.answers = q.answers.filter((q, index) => index !== i)
      }
    })
    setInputs({ ...inputs, questions: questions })
  }

  function saveAnswerType(id, type) {
    questions.map((q) => {
      if (q.id === id) {
        q.answerType = type
      }
    })
    setInputs({ ...inputs, questions: questions })
  }

  const checkQ = () => {
    let errorArr = []
    if (questions.length > 0) {
      let newArr = questions.map((q) => {
        if (q.answerType !== 'text') {
          if (q.title === '' || q.answers.length === 0) {
            let err = questionValidation({
              title: q.title,
              answers: q.answers
            })
            errorArr.push(err)
            return { ...q, errors: err }
          } else return q
        } else if (q.answerType === 'text') {
          if (q.title === '') {
            let err = questionValidation({ title: q.title })
            errorArr.push(err)
            return { ...q, errors: err }
          } else return q
        }
      })
      setInputs({ ...inputs, questions: newArr })
    } else {
      setQuestionErr(true)
    }
    return errorArr
  }
  const onSubmit = (e) => {
    e.preventDefault()
    if (inputs.tagsIds) {
      Reflect.deleteProperty(inputs, 'tagsIds')
    }
    let err = validation(inputs)
    setFormErrors(err)
    let questionsErr = checkQ()

    if (Object.keys(questions).length === 0 || Object.keys(err).length > 0) {
      return
    }
    if (questionsErr.length > 0) {
      return
    } else {
      setFormErrors(err)
      questions.map((q) => {
        if (q.answerType === 'text') {
          q.answers = []
        }
        Reflect.deleteProperty(q, 'id')
        Reflect.deleteProperty(q, 'errors')
        Reflect.deleteProperty(q, 'question')
      })
      sendData()
    }
  }

  const sendData = () => {
    try {
      dispatch(submitCreatedPoll(inputs))
      setInputs({
        ...inputs,
        name: '',
        description: '',
        instructions: '',
        tags: [],
        tagName: '',
        questions: []
      })
      setFormIsValid(false)
      setFormErrors({})
      setQuestionErr(false)
      dispatch(resetPoll(RESET_POLL_DATA))
      setTimeout(() => {
        navigate('/polls', { replace: true })
      }, 1000)
    } catch (error) {}
  }

  const checkValue = (id, name) => {
    let newArr = questions.map((q, index) => {
      if (q.id === id) {
        let err = Object.fromEntries(Object.entries(q.errors).filter(([key]) => key !== name))
        q.errors = err
        return q
      } else {
        return q
      }
    })
    setInputs({ ...inputs, questions: newArr })
  }

  const openModal = () => {
    setIsCanceled(!canceled)
  }

  const redirect = () => {
    navigate('/polls', { replace: true })
  }

  return (
    <div className='w-full'>
      {canceled && (
        <Modal
          onConfirm={redirect}
          onCancel={() => openModal()}
          message={t('POLLS.POLLS_CANCELLATION')}
        />
      )}
      {loading && <LoadingOverlay />}
      <form className='max-w-[600px] mx-auto bg-white px-[40px] py-12 shadow' onSubmit={onSubmit}>
        {error && <ErrorAlert message={t('POLLS.CREATED_POLL_FAIL')} />}
        {created && <SuccessAlert message={t('POLLS.SUCCESSFULLY_CREATED_POLL')} />}
        <h1 className='text-[28px] font-semibold text-gray-900'>{t('POLLS.POLLS_CREATE_NEW')}</h1>
        <div className='divide-y divide-gray-200'>
          <div>
            <div>
              <Input
                type='text'
                name='name'
                onChange={setVal}
                value={inputs.name}
                error={formErrors && formErrors['name']}
                label={t('POLLS.POLL_NAME')}
              />
              <TextArea
                name='description'
                onChange={setVal}
                value={inputs.description}
                error={formErrors && formErrors['description']}
                label={t('POLLS.DESCRIPTION')}
              />
              <TagsSelectInput
                label={t('POLLS.TARGETED_AUDIENCE')}
                createNew={false}
                onChange={setVal}
                tagName={'tagName'}
                value={inputs['tags']}
                name={'tags'}
                error={formErrors && formErrors['tags']}
              />
              <TextArea
                name='instructions'
                onChange={setVal}
                value={inputs.instructions}
                error={formErrors && formErrors['instructions']}
                label={t('POLLS.INSTRUCTIONS')}
              />
            </div>
          </div>
          {questionErr && (
            <p className='text-red-700 text-[14px] mt-2'>{t('VALIDATION.NO_QUESTIONS')}</p>
          )}
          {questions &&
            questions.map((question, index) => {
              return (
                <div key={index} className='block mt-8'>
                  <button
                    onClick={() => removeQuestion(question.id)}
                    type='button'
                    style={{ float: 'right', marginTop: 10 }}
                  >
                    <XCircleIcon className='w-6 h-6' />
                  </button>

                  <h1 style={{ fontWeight: 'bold', marginTop: 10 }}>{`${t('POLLS.QUESTION')} #${
                    index + 1
                  }`}</h1>

                  <div>
                    <div className='mt-4'>
                      <div>
                        <div className='mt-1'>
                          <div className='mt-4'>
                            <label className='block text-sm font-medium text-gray-700'></label>
                            <div className='mt-1'>
                              <input
                                value={question.title}
                                onChange={(e) => setTitleValue(question.id, e.target.value)}
                                id={question.id}
                                className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                              />
                              {question.errors && (
                                <p className='text-red-700 text-[14px] mt-2'>
                                  {question.errors.title}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='mt-4'>
                          <div className='flex gap-4 justify-between'>
                            <div className='w-[80%]'>
                              <p className='text-sm font-bold text-gray-700 mb-1'>
                                {t('POLLS.ANSWER_TYPE')}
                              </p>
                              <select
                                onChange={(e) => saveAnswerType(question.id, e.target.value)}
                                id='message-type'
                                name='message-type'
                                className='-ml-px block w-full rounded-l-none rounded-r-md border border-gray-300 bg-white py-2 pl-3 pr-9 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500'
                              >
                                <option value='radio'>{t('POLLS.ONE_OPTION_CHOICE')}</option>
                                <option value='checkBox'>
                                  {t('POLLS.MULTIPLE_OPTION_CHOICE')}
                                </option>
                                <option value='text'>{t('POLLS.TEXT_INPUT')}</option>
                              </select>
                            </div>
                            <div>
                              <p className='block text-sm font-medium text-gray-700'>
                                {t('POLLS.REQUIRED')}
                              </p>
                              <Toggle
                                isChecked={question.required}
                                className='mt-1 ml-auto'
                                onClick={() => setIsRequired(question.id)}
                              ></Toggle>
                            </div>
                          </div>
                        </div>

                        {question.answerType === 'text' ? (
                          <div></div>
                        ) : (
                          <div>
                            <p className='text-sm font-bold text-gray-700 mb-1 mt-3'>
                              {t('POLLS.OFFERED_ANSWERS')}
                            </p>
                            {question.answers.map((q, index) => {
                              if (q !== '') {
                                return (
                                  <div key={index} className='text-sm'>
                                    <button
                                      onClick={() => removeAnswer(question.id, index)}
                                      type='button'
                                      style={{ float: 'right' }}
                                    >
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 20 20'
                                        fill='currentColor'
                                        className='w-5 h-5'
                                      >
                                        <path d='M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z' />
                                      </svg>
                                    </button>
                                    <input
                                      disabled={true}
                                      style={{ marginRight: 10 }}
                                      name='notification-method'
                                      type={question.answerType}
                                      className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500'
                                    />
                                    {q.answer}
                                  </div>
                                )
                              }
                            })}
                          </div>
                        )}
                        {question.answerType === 'text' ? (
                          <div></div>
                        ) : (
                          <form
                            className='mt-1'
                            onSubmit={(e) => saveQuestion(question.id, question.question, e)}
                          >
                            <div className='mt-1'>
                              <input
                                name='question'
                                value={question.question}
                                placeholder={t('POLLS.TYPE_IN_ANSWER')}
                                onChange={(e) => setQuest(question.id, e.target.value)}
                                id={question.id}
                                className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                              />
                              {question.errors && (
                                <p className='text-red-700 text-[14px] mt-2'>
                                  {question.errors.answers}
                                </p>
                              )}
                            </div>
                            <SquareBtnTransparent
                              style={{
                                marginTop: '10px',
                                marginLeft: 'auto',
                                display: 'block'
                              }}
                              type='submit'
                              text={t('POLLS.SAVE')}
                              disabled={question.answerType === 'text' ? true : false}
                              onClick={(e) => saveQuestion(question.id, question.question, e)}
                            />
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
        <SquareBtnColored
          onClick={appendQuestions}
          text={t('POLLS.ADD_QUESTION')}
          style={{ marginTop: '20px', marginLeft: 'auto' }}
        />
        <div className='w-full h-[1px] bg-gray-300 mt-5'></div>
        <div className='mt-5 flex justify-start'>
          <SquareBtnColored
            type='submit'
            style={{ marginRight: 5 }}
            text={t('POLLS.SAVE')}
            onClick={onSubmit}
          />
          <SquareBtnTransparent onClick={() => openModal()} text={t('POLLS.QUIT')} />
        </div>
      </form>
    </div>
  )
}
export default CreatePollForm

import {
  TAGS_CREATE_FAIL,
  TAGS_CREATE_REQUEST,
  TAGS_CREATE_RESET,
  TAGS_CREATE_SUCCESS,
  TAGS_GET_ALL_FAIL,
  TAGS_GET_ALL_SUCCESS
} from '../constants/tagsConstants'

export const tagsGetAllReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case TAGS_GET_ALL_SUCCESS:
      return { tagsOptions: action.payload }
    case TAGS_GET_ALL_FAIL:
      error = action.payload
      return { error }
    default:
      return state
  }
}

export const tagsCreateReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case TAGS_CREATE_REQUEST:
      return { loading: true }
    case TAGS_CREATE_SUCCESS:
      return { loading: false, tag: action.payload, success: true }
    case TAGS_CREATE_FAIL:
      error = action.payload
      return { error }
    case TAGS_CREATE_RESET:
      return {}
    default:
      return state
  }
}

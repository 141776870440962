import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ErrorAlert, SuccessAlert } from '../../../../components/Alerts'
import LoadingOverlay from '../../../../components/LoadingOverlay'
import Pagination from '../../../../components/Pagination'

export const RecipientsTable = ({ onOpenModal, setOffset, offset, filterByNewTag }) => {
  const [initialLoad, setInitialLoad] = useState(false)
  const { recipientGetAll, recipientDelete } = useSelector((state) => state)
  const { loading: loadingGetAll, recipients, count } = recipientGetAll
  const { loading: loadingDelete, success: successDelete, error: errorDelete } = recipientDelete
  const { t } = useTranslation()

  const formatDate = (d) => {
    return moment(d).format('DD.MM.YYYY.')
  }

  const sendTagsToQuery = (tag) => {
    filterByNewTag(`c:${tag}`)
  }

  useEffect(() => {
    setInitialLoad(true)
  }, [])

  useEffect(() => {
    if (initialLoad) {
      setOffset(0)
    }
  }, [initialLoad, setOffset])

  return (
    <div className='mt-8 flex flex-col'>
      <div className='overflow-x-auto rounded-lg'>
        <div className='inline-block min-w-full align-middle'>
          {successDelete && (
            <SuccessAlert message={t('RECIPIENTS.SUCCESSFULLY_DELETED_RECIPIENT')} />
          )}
          {errorDelete && <ErrorAlert message={t(errorDelete)} />}
          <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg relative'>
            {loadingGetAll || (loadingDelete && <LoadingOverlay />)}
            <table className='min-w-full divide-y divide-gray-300 overflow-hidden'>
              <thead className='bg-gray-50'>
                <tr>
                  {[
                    `${t('RECIPIENTS.FIRST_LAST_NAME')}`,
                    `${t('RECIPIENTS.EMAIL')}`,
                    `${t('RECIPIENTS.DATE_OF_BIRTH')}`,
                    `${t('RECIPIENTS.TAGS')}`,
                    `${t('RECIPIENTS.RECIEVED_POLLS')}`,
                    `${t('RECIPIENTS.RECIEVED_POLLS_ERROR')}`,
                    ``
                  ].map((head, index) => {
                    return (
                      <th
                        key={index}
                        scope='col'
                        className='p-3 text-left text-sm font-semibold text-gray-900'
                      >
                        {head}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {recipients &&
                  recipients.map((recipient, index) => (
                    <tr key={index}>
                      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
                        {recipient.firstName} {recipient.lastName}
                      </td>
                      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
                        {recipient.email}
                      </td>
                      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
                        {recipient.dateOfBirth && formatDate(recipient.dateOfBirth)}
                      </td>
                      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
                        {recipient.tags.map((tag, index) => {
                          if (recipient.tags.length >= 1) {
                            if (index === recipient.tags.length - 1) {
                              return (
                                <div
                                  key={index}
                                  className='inline-block mr-1 hover:cursor-pointer'
                                  onClick={() => sendTagsToQuery(tag.name)}
                                >
                                  {tag.name}
                                </div>
                              )
                            } else {
                              return (
                                <div
                                  key={index}
                                  className='inline-block mr-1 hover:cursor-pointer'
                                  onClick={() => sendTagsToQuery(tag.name)}
                                >
                                  {tag.name},
                                </div>
                              )
                            }
                          } else {
                            return null
                          }
                        })}
                      </td>
                      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
                        {recipient.successfulSendouts}
                      </td>
                      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
                        {recipient.failedSendouts}
                      </td>
                      <td className='whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium flex items-center justify-end'>
                        <Link
                          to={`/recipients/edit/${recipient.id}`}
                          className='text-indigo-600 hover:text-indigo-900'
                          data-tooltip={t('RECIPIENTS.EDIT_RECIPIENT')}
                        >
                          <PencilSquareIcon className='w-[18px] text-gray-900' />
                        </Link>
                        <button
                          type='button'
                          onClick={() => onOpenModal(recipient.id)}
                          className='ml-2 text-indigo-600 hover:text-indigo-900'
                          data-tooltip={t('RECIPIENTS.DELETE_RECIPIENT')}
                        >
                          <TrashIcon className='w-[18px] text-gray-900' />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination count={count} onChange={setOffset} offset={offset} />
    </div>
  )
}

export default RecipientsTable

const Input = ({ value, onChange, placeholder, width, name }) => {
  return (
    <input
      type='text'
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      name={name}
      className={`outline-none ${width} border border-gray-300 p-2 border-t-0 border-x-0 placeholder:text-gray-600 placeholder:text-sm focus:ring-0 transition-colors duration-200 ease-in-out`}
    />
  )
}

export default Input

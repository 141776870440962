import { PencilSquareIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'

const EducationPosition = ({
  position,
  index,
  isEducationEditMode,
  editedRecordIndex,
  updateGraduated,
  setEducationData,
  setIsEducationEditMode,
  setEditedRecordIndex
}) => {
  const { t } = useTranslation()
  return (
    <div key={index} className='flex items-center justify-between'>
      <div className='flex items-center gap-3'>
        <div className='flex gap-1'>
          <p className='text-base text-gray-800'>
            {position.institution}
            {', '}
            {position.major && position.major}
          </p>
          <p className='text-base text-gray-800'>
            ({position.startEducationDate && new Date(position.startEducationDate).getFullYear()} -{' '}
            {position.endEducationDate
              ? new Date(position.endEducationDate).getFullYear()
              : 'Trenutno'}
            )
          </p>
        </div>
        <PencilSquareIcon
          className='w-[18px] text-gray-800 cursor-pointer'
          onClick={() => {
            setEducationData({
              id: position.id,
              institution: position.institution,
              major: position.major,
              startEducationDate:
                position.startEducationDate && new Date(position.startEducationDate),
              endEducationDate: position.endEducationDate && new Date(position.endEducationDate)
            })
            setIsEducationEditMode(true)
            setEditedRecordIndex(index)
          }}
        />
      </div>
      {isEducationEditMode && !position.endEducationDate && editedRecordIndex === index && (
        <div className='flex justify-center items-center gap-2'>
          <input
            type='checkbox'
            defaultChecked={position.graduated}
            onChange={() => updateGraduated(index)}
          />
          <span className='text-gray-500 text-xs'>{t('POSITIONS.GRADUATED')}</span>
        </div>
      )}
    </div>
  )
}

export default EducationPosition

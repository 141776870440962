import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import ReactPaginate from 'react-paginate'

const Pagination = ({ count, onChange, offset }) => {
  if (isNaN(count)) {
    count = 0
  } else {
    count = count / 10
  }

  return (
    <div className='pagination-holder'>
      <ReactPaginate
        onPageChange={(value) => onChange(value.selected)}
        forcePage={offset}
        breakLabel='...'
        nextLabel={<ChevronRightIcon />}
        pageRangeDisplayed={1}
        pageCount={Math.ceil(count)}
        previousLabel={<ChevronLeftIcon />}
        renderOnZeroPageCount={null}
      />
    </div>
  )
}

export default Pagination

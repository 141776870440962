import { omit } from 'lodash'
import { isRequired } from './index'
export const validation = (inputs) => {
  let errors = {}
  for (const [key, value] of Object.entries(inputs)) {
    switch (key) {
      case 'email':
      case 'password':
        errors[key] = isRequired(value)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
    }
  }
  return errors
}

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import PieChart from './PieChart'

export const RadioTypeForm = ({ question, index, poll, sentTo }) => {
  const location = useLocation()
  const { t } = useTranslation()
  const { answers, title, id } = question
  const answersNames = answers.map((answer) => answer.answer)
  const pollQuestionAnswers = poll.answers.filter((answer) => answer.questionId === id)

  const bgColors = ['#3566CD', '#10971A', '#0099C7', '#DA3814', '#FA9704', '#960198', '#3566CD']
  const brColors = ['#3566CD', '#10971A', '#0099C7', '#DA3814', '#FA9704', '#960198', '#3566CD']

  const getNumOfSpecificAnswer = (answerId) => {
    const numOfSpecificAnswer = poll.answers.filter(
      (answer) => answer.questionId === id && answerId === answer.answer
    ).length
    return numOfSpecificAnswer
  }

  function getNbOccur(answer, arr) {
    let occurs = 0
    for (let i = 0; i < arr.length; i++) {
      if ('answer' in arr[i] && arr[i].answer === answer) occurs++
    }
    return occurs
  }

  const questionResults = answers.map((answer) => {
    return getNbOccur(answer.id, pollQuestionAnswers)
  })

  return (
    <>
      <div className='flex flex-col gap-2 pagebreak'>
        <div className='mb-2'>
          <h3 className='text-[14px] font-bold leading-6 text-gray-900'>
            {index + 1}. {title}
          </h3>
          <h3 className='text-[10px] font-medium text-gray-700 italic'>
            {t('POLL_RESULTS.ONE_POSSIBLE_ANSWER')}
          </h3>
        </div>
        <div className='flex gap-2 justify-between'>
          <div className='flex flex-col gap-1'>
            {answers &&
              answers
                .sort((a, b) => {
                  return getNumOfSpecificAnswer(b.id) - getNumOfSpecificAnswer(a.id)
                })
                .map((answer, index) => (
                  <div key={index} className='flex justify-between'>
                    <div className='flex gap-1 items-center'>
                      {location.pathname.includes('download') ? null : (
                        <div
                          id='radiotypeform'
                          style={{
                            backgroundColor:
                              getNumOfSpecificAnswer(answer.id) > 0 ? bgColors[index] : 'white',
                            border: '1px solid',
                            borderColor:
                              getNumOfSpecificAnswer(answer.id) > 0 ? brColors[index] : 'gray',
                            width: '20px',
                            height: '15px'
                          }}
                        ></div>
                      )}
                      <p className='text-[11px]'>{answer.answer}</p>
                    </div>
                    <div className='flex gap-1 items-center'>
                      <p className='ml-5 text-[11px]'>
                        {Math.round((getNumOfSpecificAnswer(answer.id) / sentTo) * 100)}%
                      </p>
                      <p className='text-[10px] text-gray-700'>
                        ({getNumOfSpecificAnswer(answer.id)}/{sentTo})
                      </p>
                    </div>
                  </div>
                ))}
          </div>
          <div className='flex justify-between mt-12 md:mt-0'>
            {location.pathname.includes('download') ? null : (
              <PieChart answers={answersNames} results={questionResults} sentTo={sentTo} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default RadioTypeForm

import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ErrorAlert, SuccessAlert } from '../../../../components/Alerts'
import LoadingOverlay from '../../../../components/LoadingOverlay'
import Pagination from '../../../../components/Pagination'
import { getAll } from '../../../../redux/actions/userActions'

export const UsersTable = ({ onOpenModal, userQuery, setOffset, offset }) => {
  const [initialLoad, setInitialLoad] = useState(false)
  const { userGetAll, userDelete } = useSelector((state) => state)
  const { loading: loadingGetAll, users, count } = userGetAll
  const { loading: loadingDelete, success: successDelete, error: errorDelete } = userDelete

  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    setInitialLoad(true)
    dispatch(getAll(offset, userQuery))
  }, [offset, userQuery, dispatch])

  useEffect(() => {
    if (initialLoad) {
      setOffset(0)
    }
  }, [userQuery, setOffset, initialLoad])

  return (
    <div className='mt-8 flex flex-col'>
      <div className='overflow-x-auto rounded-lg'>
        <div className='inline-block min-w-full align-middle'>
          {successDelete && <SuccessAlert message={t('USERS.SUCCESSFULLY_DELETED_USER')} />}
          {errorDelete && <ErrorAlert message={t(errorDelete)} />}
          <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg relative'>
            {loadingGetAll || (loadingDelete && <LoadingOverlay />)}
            <table className='min-w-full divide-y divide-gray-300 overflow-hidden'>
              <thead className='bg-gray-50'>
                <tr>
                  {[`${t('USERS.USER_NAME')}`, `${t('USERS.EMAIL')}`, ``].map((head, index) => {
                    return (
                      <th
                        key={index}
                        scope='col'
                        className='p-3 text-left text-sm font-semibold text-gray-900'
                      >
                        {head}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {users &&
                  users.map((user, index) => (
                    <tr key={index}>
                      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
                        {user.userName}
                      </td>
                      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>{user.email}</td>
                      <td className='whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium flex items-center justify-end'>
                        <Link
                          to={`/users/edit/${user.id}`}
                          className='text-indigo-600 hover:text-indigo-900'
                          data-tooltip={t('USERS.EDIT_USER')}
                        >
                          <PencilSquareIcon className='w-[18px] text-gray-900' />
                        </Link>
                        <button
                          type='button'
                          onClick={() => onOpenModal(user.id)}
                          className='ml-2 text-indigo-600 hover:text-indigo-900'
                          data-tooltip={t('USERS.DELETE_USER')}
                        >
                          <TrashIcon className='w-[18px] text-gray-900' />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination count={count} onChange={setOffset} offset={offset} />
    </div>
  )
}

export default UsersTable

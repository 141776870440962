import { api } from '../../services/api.service'
import { create, remove } from '../../services/token.service'
import { GLOBAL_RESET } from '../constants/globalConstants'
import {
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_GET_ALL_FAIL,
  USER_GET_ALL_REQUEST,
  USER_GET_ALL_SUCCESS,
  USER_GET_ONE_FAIL,
  USER_GET_ONE_REQUEST,
  USER_GET_ONE_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT_FAIL,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS
} from '../constants/userConstants'

export const signin = (inputs) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST, payload: {} })
  await api
    .post('/auth/signin', inputs)
    .then((res) => {
      create(res.data)
      dispatch({ type: USER_SIGNIN_SUCCESS, payload: true })
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: USER_SIGNIN_FAIL, payload: err.response.data.message })
    })
}

export const signout = () => async (dispatch) => {
  await api
    .get('/auth/logout')
    .then((res) => {
      remove()
      dispatch({ type: GLOBAL_RESET, payload: true })
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: USER_SIGNOUT_FAIL, payload: err.response.data.message })
    })
}

export const getAll =
  (offset = 0, queryString = '') =>
  async (dispatch) => {
    dispatch({ type: USER_GET_ALL_REQUEST, payload: {} })
    await api
      .get(`/user`, { params: { offset, query: queryString } })
      .then((res) => {
        dispatch({ type: USER_GET_ALL_SUCCESS, payload: res.data })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: USER_GET_ALL_FAIL,
          payload: err.response.data.message
        })
      })
  }

export const deleteUser = (id) => async (dispatch) => {
  dispatch({ type: USER_DELETE_REQUEST, payload: {} })
  await api
    .delete(`/user/${id}`)
    .then((res) => {
      dispatch({ type: USER_DELETE_SUCCESS, payload: res.data.success })
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: USER_DELETE_FAIL, payload: err.response.data.message })
    })
}

export const createUser = (inputs) => async (dispatch) => {
  dispatch({ type: USER_CREATE_REQUEST, payload: {} })
  await api
    .post(`/user`, inputs)
    .then((res) => {
      dispatch({ type: USER_CREATE_SUCCESS, payload: res.data.success })
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: USER_CREATE_FAIL, payload: err.response.data.message })
    })
}

export const updateUser = (inputs, id) => async (dispatch) => {
  dispatch({ type: USER_UPDATE_REQUEST, payload: {} })
  const data = {}
  for (const key in inputs) {
    if (key === 'password' || key === 'repeatedPassword') {
      if (inputs['password'].length > 0) {
        data[key] = inputs[key]
      }
    } else {
      data[key] = inputs[key]
    }
  }
  await api
    .patch(`/user/${id}`, data)
    .then((res) => {
      dispatch({ type: USER_UPDATE_SUCCESS, payload: res.data.user })
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: USER_UPDATE_FAIL, payload: err.response.data.message })
    })
}

export const getOneUser = (id) => async (dispatch) => {
  dispatch({ type: USER_GET_ONE_REQUEST, payload: {} })
  await api
    .get(`/user/${id}`)
    .then((res) => {
      dispatch({ type: USER_GET_ONE_SUCCESS, payload: res.data.user })
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: USER_GET_ONE_FAIL, payload: err.response.data.message })
    })
}

import { XMarkIcon } from '@heroicons/react/20/solid'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

const WarningModal = ({ message, onCancel }) => {
  return (
    <div>
      <div
        className='fixed w-full h-full bg-white z-[52] left-0 top-0 bg-opacity-80'
        onClick={onCancel}
      ></div>
      <div className='rounded-lg shadow fixed bg-white z-[53] max-w-[400px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]'>
        <button
          onClick={onCancel}
          type='button'
          className='absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center'
        >
          <XMarkIcon className='w-5 h-5' />
        </button>
        <div className='p-6 text-center'>
          <ExclamationCircleIcon className='mx-auto mb-4 text-gray-400 w-14 h-14' />
          <h3 className='mb-5 text-lg font-normal text-gray-500'>{message}</h3>
        </div>
      </div>
    </div>
  )
}

export default WarningModal

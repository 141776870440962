import {
  ArrowLeftOnRectangleIcon,
  ClipboardDocumentListIcon,
  Squares2X2Icon,
  UserGroupIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Logo from '../../assets/images/logo.png'

const SideNav = ({ setIsOpenedSideNav, onSignout, isOpenedSideNav, location }) => {
  const { t } = useTranslation()

  const sideBarLinks = [
    {
      name: t('SIDE_NAV.POLLS'),
      path: '/polls',
      icon: ClipboardDocumentListIcon
    },
    {
      name: t('SIDE_NAV.RECIPIENTS'),
      path: '/recipients',
      icon: UserGroupIcon
    },
    { name: t('SIDE_NAV.USERS'), path: '/users', icon: Squares2X2Icon }
  ]

  return (
    <div
      className={`h-screen sidenav ${
        isOpenedSideNav && 'opened'
      } z-[51] top-0 p-3 bg-white shadow w-[200px]`}
    >
      <div className='space-y-3'>
        <button
          onClick={() => setIsOpenedSideNav(false)}
          type='button'
          className='ml-auto close-sidenav'
        >
          <XMarkIcon className='w-5 h-5' />
        </button>
        <div className='flex items-center justify-center'>
          <img src={Logo} alt='logo' className='' />
        </div>
        <div className='flex-1'>
          <ul className='pt-2 pb-4 space-y-1 text-sm'>
            {sideBarLinks.map((link, index) => (
              <li key={index} className='rounded-sm'>
                <Link
                  onClick={() => setIsOpenedSideNav(false)}
                  to={link.path}
                  className={`${
                    location.pathname.includes(link.path)
                      ? 'bg-gray-100 text-gray-900 '
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  } group flex items-center px-2 py-2 text-base font-medium rounded-md`}
                >
                  {
                    <link.icon
                      className={`${
                        location.pathname.includes(link.path)
                          ? 'text-gray-500'
                          : 'text-gray-400 group-hover:text-gray-500'
                      } mr-3 flex-shrink-0 h-7 w-7`}
                    />
                  }
                  <span>{link.name}</span>
                </Link>
              </li>
            ))}
            <li className='rounded-sm'>
              <button
                type='button'
                onClick={onSignout}
                className='w-full text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md'
              >
                <ArrowLeftOnRectangleIcon className='text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-7 w-7' />
                <span>{t('SIDE_NAV.SIGN_OUT')}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SideNav

import { omit } from 'lodash'
import i18n from './i18n'
import { isRequired } from './index'
export const validation = (inputs) => {
  let errors = {}
  for (const [key, value] of Object.entries(inputs)) {
    switch (key) {
      case 'name':
        errors[key] = isRequired(value)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'description':
        errors[key] = isRequired(value)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'tagName':
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'instructions':
        errors[key] = isRequired(value)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'tags':
        if (value.length === 0) {
          errors[key] = i18n.t('VALIDATION.REQUIRED')
        }
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'questions':
        if (value.length === 0) {
          errors[key] = 'You need to add at least one question'
        }
        break
      default:
        return {}
    }
  }
  return errors
}

export const questionValidation = (inputs) => {
  let errors = {}
  for (const [key, value] of Object.entries(inputs)) {
    switch (key) {
      case 'title':
        errors[key] = isRequired(value)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'answers':
        errors[key] = isRequired(value)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      default:
        return 'default case'
    }
  }
  return errors
}

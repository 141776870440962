import { api } from '../../services/api.service'
import {
  CLONE_POLL,
  CREATED_POLL_FAIL,
  CREATED_POLL_SUCCESS,
  DELETE_POLL,
  DELETE_POLL_FAIL,
  DELETE_POLL_SUCCESS,
  EDITED_POLL_FAIL,
  EDITED_POLL_SUCCESS,
  FINISH_POLL_FAIL,
  FINISH_POLL_SUCCESS,
  GET_ALL_POLLS,
  GET_ALL_POLLS_FAIL,
  GET_ALL_POLLS_REQUEST,
  GET_POLL,
  GET_POLL_FAIL,
  GET_POLL_SUCCESS,
  PUBLISH_POLL_FAIL,
  PUBLISH_POLL_REQUEST,
  PUBLISH_POLL_SUCCESS,
  RESET_EDIT_POLL,
  RESET_FINISH_DATA,
  RESET_GET_POLL,
  RESET_POLL_DATA,
  RESET_PUBLISH_DATA,
  SUBMIT_CREATED_POLL,
  SUBMIT_EDITED_POLL
} from '../constants/pollConstants'

export const editPoll = (id, data) => async (dispatch) => {
  const tagsIds = data.tags.map((tag) => tag.value)
  data.tagsIds = tagsIds
  dispatch({ type: SUBMIT_EDITED_POLL, payload: {} })
  await api
    .patch(`/polls/${id}`, data)
    .then((res) => {
      dispatch({ type: EDITED_POLL_SUCCESS, payload: res.data })
    })
    .catch((err) => {
      console.log('this is the error:', err)
      dispatch({ type: EDITED_POLL_FAIL, payload: err.response.data.message })
    })
}

export const publishPoll = (id) => async (dispatch) => {
  dispatch({ type: PUBLISH_POLL_REQUEST })
  await api
    .post(`/polls/publish`, { id })
    .then((res) => {
      dispatch({ type: PUBLISH_POLL_SUCCESS, payload: res.data.success })
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: PUBLISH_POLL_FAIL, payload: err.response.data.message })
    })
}

export const finishPoll = (id) => async (dispatch) => {
  await api
    .post(`/polls/finish`, { id })
    .then((res) => {
      dispatch({ type: FINISH_POLL_SUCCESS, payload: res.data.success })
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: FINISH_POLL_FAIL, payload: err.response.data.message })
    })
}

export const resetPoll = () => (dispatch) => {
  dispatch({ type: RESET_POLL_DATA, payload: {} })
}
export const resetEditedPoll = () => (dispatch) => {
  dispatch({ type: RESET_EDIT_POLL, payload: {} })
}

export const resetPollById = () => (dispatch) => {
  dispatch({ type: RESET_GET_POLL, payload: {} })
}
export const resetPublishData = () => (dispatch) => {
  dispatch({ type: RESET_PUBLISH_DATA, payload: {} })
}
export const resetFinishData = () => (dispatch) => {
  dispatch({ type: RESET_FINISH_DATA, payload: {} })
}

export const submitCreatedPoll = (data) => async (dispatch) => {
  dispatch({ type: SUBMIT_CREATED_POLL, payload: {} })
  const tagsIds = data.tags.map((tag) => tag.value)
  data.tagsIds = tagsIds
  await api
    .post('/polls/create', data)
    .then((res) => {
      dispatch({ type: CREATED_POLL_SUCCESS, payload: res.data })
    })
    .catch((err) => {
      console.log('this is the error:', err)
      dispatch({ type: CREATED_POLL_FAIL, payload: err.response.data.message })
    })
}

export const crudPolls = (type, data) => async (dispatch) => {
  switch (type) {
    case GET_ALL_POLLS:
      dispatch({ type: GET_ALL_POLLS_REQUEST, payload: {} })
      await api
        .get(`/polls`, {
          params: { offset: data.offset, query: data.queryString }
        })
        .then((res) => {
          dispatch({ type: GET_ALL_POLLS, payload: res.data })
        })
        .catch((err) => {
          console.log(err)
          dispatch({ type: GET_ALL_POLLS_FAIL, payload: err.response })
        })
      break
    case GET_POLL:
      await api
        .get(`/polls/${data}`)
        .then((res) => {
          dispatch({ type: GET_POLL, payload: res.data })
        })
        .catch((err) => {
          console.log(err)
          dispatch({ type: GET_POLL_FAIL, payload: err.response.data.message })
        })
      break
    case CLONE_POLL:
      await api
        .post('/polls/create', data)
        .then((res) => {
          dispatch({ type: CLONE_POLL, payload: res.data })
        })
        .catch((err) => {
          dispatch({ type: CREATED_POLL_FAIL, payload: err })
        })
      break
    case DELETE_POLL:
      dispatch({ type: DELETE_POLL, payload: {} })
      await api
        .delete(`/polls/${data}`)
        .then((res) => {
          dispatch({ type: DELETE_POLL_SUCCESS, payload: data })
        })
        .catch((err) => {
          console.log(err)
          dispatch({
            type: DELETE_POLL_FAIL,
            payload: err.response.data.message
          })
        })
      break
    default:
      return
  }
}

export const getPollByID = (id) => async (dispatch) => {
  dispatch({ type: GET_POLL, payload: {} })
  await api
    .get(`/polls/${id}`)
    .then((res) => {
      dispatch({ type: GET_POLL_SUCCESS, payload: res.data })
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: GET_POLL_FAIL, payload: err.response.data.message })
    })
}

import { useTranslation } from 'react-i18next'

export const TextTypeForm = ({ question, index, error, submitted, setVal }) => {
  const { required, title, id } = question
  const { t } = useTranslation()
  return (
    <div>
      <div>
        <h3 className='text-lg font-medium leading-6 text-gray-900'>
          {index + 1}. {title}
          {required && <span className='text-red-500'>*</span>}
        </h3>
        {error && submitted && (
          <p className='text-red-700 text-[14px] mt-2'>
            {t('POLL_SUBMISION.THIS_QUESTION_IS_REQUIRED')}
          </p>
        )}
      </div>
      <div className='mt-4'>
        <textarea
          rows={3}
          onChange={(e) => setVal({ questionId: id, value: e.target.value, type: 'radio' })}
          className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
          defaultValue={''}
        />
      </div>
    </div>
  )
}

export default TextTypeForm

import { useTranslation } from 'react-i18next'

export const RadioTypeForm = ({ question, index, error, submitted, setVal }) => {
  const { required, answers, title, id } = question
  const { t } = useTranslation()
  return (
    <div>
      <div>
        <h3 className='text-lg font-medium leading-6 text-gray-900'>
          {index + 1}. {title}
          {required && <span className='text-red-500'>*</span>}
        </h3>
        {error && submitted && (
          <p className='text-red-700 text-[14px] mt-2'>
            {t('POLL_SUBMISION.THIS_QUESTION_IS_REQUIRED')}
          </p>
        )}
      </div>
      <div className='mt-4'>
        {answers &&
          answers.map((answer, index) => (
            <div key={index} className='flex items-center mt-2'>
              <input
                id={answer.id}
                value={answer.id}
                onChange={(e) =>
                  setVal({
                    questionId: id,
                    value: e.target.value,
                    type: 'radio'
                  })
                }
                name={id}
                type='radio'
                className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500'
              />
              <label
                htmlFor={answer.id}
                className='hover:cursor-pointer ml-3 block text-sm font-medium text-gray-700'
              >
                {answer.answer}
              </label>
            </div>
          ))}
      </div>
    </div>
  )
}

export default RadioTypeForm

import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { getPollResults } from '../../../../redux/actions/pollResultsActions'
import { api } from '../../../../services/api.service'
import { CheckTypeForm, RadioTypeForm, TextTypeForm } from './components'

export const PollResultsPDF = () => {
  const [recipientNumbers, setRecipientNumbers] = useState([])
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { pollResultsGet } = useSelector((state) => state)
  const { poll } = pollResultsGet
  const userSignin = useSelector((state) => state.userSignin)
  const { isSignin } = userSignin
  const pageRef = useRef(null)
  const tagsRef = useRef(null)

  useEffect(() => {
    dispatch(getPollResults(params.id))
  }, [dispatch, params.id])

  useEffect(() => {
    if (!isSignin) {
      navigate('/signin', { replace: true })
    }
  }, [isSignin, navigate])

  useEffect(() => {
    const getRecipientsNumbers = async () => {
      await api.get(`/polls/recipients-numbers/${params.id}`).then((res) => {
        setRecipientNumbers(res.data)
      })
    }
    getRecipientsNumbers()
  }, [params.id])

  useEffect(() => {
    const handleKeyDown = (e) => {
      const tags = tagsRef.current
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 80) {
        e.preventDefault()
        tags.childNodes.forEach((tag) => {
          tag.style.backgroundColor = 'white'
        })
        window.print()
      }
    }
    window.onafterprint = () => {
      tagsRef.current.childNodes.forEach((tag) => {
        tag.style.backgroundColor = 'rgb(238 238 238)'
      })
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <div className='flex w-full min-h-screen bg-gray-200 relative'>
      <div className='w-full md:w-[8.3in] mx-auto bg-white'>
        {poll && recipientNumbers && (
          <div className='px-[0.5in] py-[0.25in]' ref={pageRef}>
            <div className='flex justify-between items-center mb-5'>
              <div className='flex'>
                <h3 className='text-lg font-bold leading-6 text-gray-900'>
                  {t('POLL_RESULTS.POLL_RESULTS')}:&nbsp;
                </h3>
                <p>{poll.name}</p>
              </div>
            </div>
            <div className='flex items-center gap-1 mb-3'>
              <p className='text-gray-900 text-[13px]'>{t('POLL_RESULTS.TARGETED_AUDIENCE')}</p>
              <div className='flex gap-2' ref={tagsRef}>
                {poll.tags.map((tag, index) => (
                  <div
                    key={index}
                    className='bg-gray-200 text-gray-900 text-[11px] px-2 py-1 rounded'
                  >
                    {tag.name}
                  </div>
                ))}
              </div>
            </div>
            <div className='mt-2 flex items-start gap-1'>
              <h3 className='text-[13px] font-medium text-gray-900'>
                {t('POLL_RESULTS.INSTRUCTIONS')}
              </h3>
              <p className='font-medium text-gray-900 text-[13px]'>{poll.instructions}</p>
            </div>
            <div className='mt-2 flex gap-1 items-start mb-3'>
              <h3 className='font-medium text-gray-900 text-[13px]'>
                {t('POLL_RESULTS.DESCRIPTION')}
              </h3>
              <p className='ml-[8px] font-medium text-gray-900 text-[13px]'>{poll.description}</p>
            </div>
            <div className='flex justify-between flex-wrap gap-4 items-center'>
              <div className='flex flex-col'>
                <div className='flex items-center gap-1'>
                  <h3 className='text-[13px] font-medium leading-6 text-gray-900'>
                    {t('POLL_RESULTS.TOTAL_RECIPIENTS')}{' '}
                    <strong>{recipientNumbers.recipientsFromTags} </strong>
                    {t('POLL_RESULTS.TOTAL_RECIPIENTS_END')}
                  </h3>
                </div>
                <div className='flex items-center gap-1'>
                  <h3 className='text-[13px] font-medium leading-6 text-gray-900'>
                    {t('POLL_RESULTS.NUMBER_OF_RECIPIENTS')}{' '}
                    <strong>{recipientNumbers.sentToRecipients} </strong>
                    {t('POLL_RESULTS.NUMBER_OF_RECIPIENTS_END')}
                  </h3>
                </div>
                <div className='flex items-center gap-1'>
                  <h3 className='text-[13px] font-medium leading-6 text-gray-900'>
                    {t('POLL_RESULTS.PARTICIPATED')}{' '}
                    <strong>{recipientNumbers.recipientsWhoCompleted} </strong>
                    {t('POLL_RESULTS.PARTICIPATED_END')}
                  </h3>
                </div>
              </div>
            </div>
            <div className='mt-[20px] flex flex-col gap-10'>
              {poll.questions.map((question, index) => {
                switch (question.answerType) {
                  case 'checkBox':
                    return (
                      <CheckTypeForm
                        key={index}
                        question={question}
                        index={index}
                        poll={poll}
                        sentTo={recipientNumbers.recipientsWhoCompleted}
                      />
                    )
                  case 'radio':
                    return (
                      <RadioTypeForm
                        key={index}
                        question={question}
                        index={index}
                        poll={poll}
                        sentTo={recipientNumbers.recipientsWhoCompleted}
                      />
                    )
                  case 'text':
                    return (
                      <TextTypeForm
                        key={index}
                        question={question}
                        index={index}
                        poll={poll}
                        sentTo={recipientNumbers.recipientsWhoCompleted}
                      />
                    )
                }
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PollResultsPDF

import { useTranslation } from 'react-i18next'
const PositionsActionMessages = () => {
  const { t } = useTranslation()
  return (
    <div className='flex flex-col min-h-screen justify-center pb-[40px] pt-[20px] sm:px-6 lg:px-8 px-4 bg-gray-200'>
      <form className='w-full max-w-[700px] mx-auto bg-white px-[40px] pt-[44px] pb-[40px] shadow relative mb-40'>
        <div className='mt-1'>
          <h3 className='text-2xl font-medium leading-6 text-gray-900'>
            {t('POSITIONS.FINISHED_MESSAGE_HEADER')}
          </h3>
          <p className='mt-1 text-sm text-gray-500'>{t('POSITIONS.FINISHED_MESSAGE')}</p>
        </div>
      </form>
    </div>
  )
}

export default PositionsActionMessages

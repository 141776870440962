import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Modal from '../components/Modal'
import { signout } from '../redux/actions/userActions'
import Breadcrumb from './components/Breadcrumb'
import SideNav from './components/SideNav'

export const DashboardLayout = () => {
  const location = useLocation()
  const { t } = useTranslation()

  const [isModal, setIsModal] = useState(false)
  const [isOpenedSideNav, setIsOpenedSideNav] = useState(false)
  const userSignin = useSelector((state) => state.userSignin)
  const { isSignin } = userSignin

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isSignin) {
      navigate('/signin', { replace: true })
    }
  }, [isSignin, navigate])

  const onSignout = () => {
    setIsModal(true)
  }
  return (
    <>
      <div className='dashboard-layout flex bg-gray-200'>
        {isModal && (
          <Modal
            onCancel={() => setIsModal(false)}
            onConfirm={() => dispatch(signout())}
            message={t('SIDE_NAV.LOGOUT_MESSAGE')}
          />
        )}
        <SideNav
          onSignout={onSignout}
          setIsOpenedSideNav={setIsOpenedSideNav}
          isOpenedSideNav={isOpenedSideNav}
          location={location}
        />
        <div className='w-full overflow-hidden'>
          <Breadcrumb location={location} onClick={() => setIsOpenedSideNav(true)} />
          <div className='relative mx-auto max-w-7xl px-6 md:px-8 py-10 w-full flex justify-center items-center'>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardLayout

import { useTranslation } from 'react-i18next'

export const TextTypeForm = ({ question, index, poll }) => {
  const { t } = useTranslation()
  const { title, id } = question
  const pollQuestionAnswers = poll.answers.filter(
    (answer) => answer.questionId === id && answer.answer !== ''
  )
  return (
    <>
      <div className='flex gap-2 justify-between flex-col'>
        <div className='mb-2'>
          <h3 className='text-[14px] font-bold leading-6 text-gray-900'>
            {index + 1}. {title}
          </h3>
          <h3 className='text-[10px] font-medium text-gray-700 italic'>
            {t('POLL_RESULTS.FREE_ENTRY')}
          </h3>
        </div>
        <ul className='w-full'>
          {pollQuestionAnswers &&
            pollQuestionAnswers.map((answer, index) => (
              <li key={index} className='text-[11px] font-medium list-disc list-inside'>
                {answer.answer}
              </li>
            ))}
        </ul>
      </div>
    </>
  )
}

export default TextTypeForm

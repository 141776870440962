import { api } from '../../services/api.service'
import {
  SUBMISION_GET_DATA_FAIL,
  SUBMISION_GET_DATA_REQUEST,
  SUBMISION_GET_DATA_SUCCESS,
  SUBMISION_SUBMIT_FAIL,
  SUBMISION_SUBMIT_REQUEST,
  SUBMISION_SUBMIT_SUCCESS
} from '../constants/pollSubmisionConstants'

export const getPositionData = (token) => async (dispatch) => {
  dispatch({ type: SUBMISION_GET_DATA_REQUEST, payload: {} })
  await api
    .get('/positions', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => {
      dispatch({ type: SUBMISION_GET_DATA_SUCCESS, payload: res.data })
    })
    .catch((err) => {
      dispatch({
        type: SUBMISION_GET_DATA_FAIL,
        payload: err.response.data.message
      })
    })
}

export const getAllPositions = () => async (dispatch) => {
  dispatch({ type: SUBMISION_GET_DATA_REQUEST, payload: {} })
  await api
    .get('/positions/all')
    .then((res) => {
      dispatch({ type: SUBMISION_GET_DATA_SUCCESS, payload: res.data })
    })
    .catch((err) => {
      dispatch({
        type: SUBMISION_GET_DATA_FAIL,
        payload: err.response.data.message
      })
    })
}

export const submitPosition = (data, token) => async (dispatch) => {
  dispatch({ type: SUBMISION_SUBMIT_REQUEST, payload: {} })
  const { type, recipientId, educationData, unsubscribed, workData } = data
  const payload = {
    type: type,
    recipientId: recipientId,
    unsubscribed: unsubscribed,
    educationData: educationData,
    workData: workData
  }
  await api
    .post('/positions', payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => {
      dispatch({ type: SUBMISION_SUBMIT_SUCCESS, payload: res.data.tag })
    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: SUBMISION_SUBMIT_FAIL,
        payload: err.response.data.message
      })
    })
}

export const updatePosition = (data, token) => async (dispatch) => {
  dispatch({ type: SUBMISION_SUBMIT_REQUEST, payload: {} })
  const { id, type, recipientId, educationData, unsubscribed, workData } = data
  const payload = {
    id: id,
    type: type,
    recipientId: recipientId,
    unsubscribed: unsubscribed,
    educationData: educationData,
    workData: workData
  }
  await api
    .patch('/positions', payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => {
      dispatch({ type: SUBMISION_SUBMIT_SUCCESS, payload: res.data.tag })
    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: SUBMISION_SUBMIT_FAIL,
        payload: err.response.data.message
      })
    })
}

export const unsubscribeGraduate = async (recipientId, token, value) => {
  try {
    const response = await api.patch(
      '/positions/unsubscribe',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          recipientId: recipientId,
          value: value
        }
      }
    )
    return response.data
  } catch (error) {
    console.error('Error unsubscribing graduate:', error)
    throw error
  }
}

export const getRecipientData = async (recipientId, token) => {
  try {
    const response = await api.get(`/recipient/find-recipient-birthday-poll/${recipientId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return response.data
  } catch (error) {
    console.error('Error fetching recipient data:', error)
    throw error
  }
}

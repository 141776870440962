import { PencilSquareIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'

const WorkPosition = ({
  position,
  index,
  isWorkEditMode,
  editedRecordIndex,
  updateFinished,
  setWorkData,
  setIsWorkEditMode,
  setEditedRecordIndex
}) => {
  const { t } = useTranslation()
  return (
    <div key={index} className='flex items-center justify-between'>
      <div className='flex items-center gap-3'>
        <div className='flex gap-1'>
          <p className='text-base text-gray-800'>
            {position.company}
            {', '}
            {position.position && position.position}
          </p>
          <p className='text-base text-gray-800'>
            ({position.startWorkDate && new Date(position.startWorkDate).getFullYear()} -{' '}
            {position.endWorkDate ? new Date(position.endWorkDate).getFullYear() : 'Trenutno'})
          </p>
        </div>
        <PencilSquareIcon
          className='w-[18px] text-gray-800 cursor-pointer'
          onClick={() => {
            setWorkData({
              id: position.id,
              company: position.company,
              position: position.position,
              startWorkDate: position.startWorkDate && new Date(position.startWorkDate),
              endWorkDate: position.endWorkDate && new Date(position.endWorkDate)
            })
            setIsWorkEditMode(true)
            setEditedRecordIndex(index)
          }}
        />
      </div>
      {isWorkEditMode && !position.endWorkDate && editedRecordIndex === index && (
        <div className='flex justify-center items-center gap-2'>
          <input
            type='checkbox'
            defaultChecked={position.finished}
            onChange={() => updateFinished(index)}
          />
          <span className='text-gray-500 text-xs'>{t('POSITIONS.GRADUATED')}</span>
        </div>
      )}
    </div>
  )
}

export default WorkPosition

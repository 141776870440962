import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Logo from '../../../src/assets/images/logo.png'
import ESF_Lenta from '../../assets/images/banner.png'
import { ErrorAlert } from '../../components/Alerts'
import { SquareBtnColored, SquareBtnTransparent } from '../../components/FormComponents'
import LoadingOverlay from '../../components/LoadingOverlay'
import Modal from '../../components/Modal'
import { getSubmisionData, submitPoll } from '../../redux/actions/pollSubmisionActions'
import { CheckTypeForm, RadioTypeForm, TextTypeForm } from './components'

const PollSubmision = () => {
  const [isModal, setIsModal] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const params = useParams()
  const [userAnswers, setUserAnswers] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [isPollWrong, setIsPollWrong] = useState(false)

  const { pollSubmisionGetData, pollSubmisioSubmit } = useSelector((state) => state)
  const {
    loading: getSubmisionDataLoading,
    poll,
    error: pollSubmisionGetDataError
  } = pollSubmisionGetData
  const { loading: submitPollLoading, success: submitPollSuccess } = pollSubmisioSubmit

  useEffect(() => {
    dispatch(getSubmisionData(params.userIdentificator))
  }, [dispatch, params.userIdentificator])

  useEffect(() => {
    if (poll) {
      let loadAnswers = []
      poll.questions.map((question, index) => {
        loadAnswers = [
          ...loadAnswers,
          {
            questionId: question.id,
            required: question.required,
            answerType: question.answerType,
            answer: question.answerType === 'checkBox' ? [] : '',
            pollId: poll.id
          }
        ]
      })
      setUserAnswers(loadAnswers)
    }
  }, [poll])

  useEffect(() => {
    if (submitPollSuccess) {
      navigate(`/poll/submitted`, { replace: true })
    }
  }, [submitPollSuccess, navigate])

  useEffect(() => {
    if (pollSubmisionGetDataError) {
      switch (pollSubmisionGetDataError) {
        case 'POLL_ALREADY_SUBMITTED':
          navigate(`/poll/completed`, { replace: true })
          break
        case 'POLL_FINISHED':
          navigate(`/poll/finished`, { replace: true })
          break
        case 'POLL_IN_PROGRESS':
          navigate(`/poll/in-progress`, { replace: true })
          break
        default:
          break
      }
    }
  }, [pollSubmisionGetDataError, navigate])

  const setVal = ({ questionId, value, type }) => {
    let setAnswers = []
    switch (type) {
      case 'radio':
        setAnswers = userAnswers.map((userAnswer) => {
          if (userAnswer.questionId === questionId) {
            return { ...userAnswer, answer: value }
          } else {
            return userAnswer
          }
        })
        break
      case 'checkBox':
        setAnswers = userAnswers.map((userAnswer) => {
          if (userAnswer.questionId === questionId) {
            let answers
            if (!userAnswer.answer) {
              answers = [value]
            } else {
              answers = userAnswer.answer
              const valueIndex = answers.indexOf(value)
              valueIndex === -1 ? answers.push(value) : answers.splice(valueIndex, 1)
            }
            return { ...userAnswer, answer: answers }
          } else {
            return userAnswer
          }
        })
        break
      case 'text':
        setAnswers = userAnswers.map((userAnswer) => {
          if (userAnswer.questionId === questionId) {
            return { ...userAnswer, answer: value }
          } else {
            return userAnswer
          }
        })
        break
      default:
        break
    }
    setUserAnswers(setAnswers)
  }

  const onPollSubmision = (e) => {
    e.preventDefault()
    const errors = userAnswers.filter(
      (userAnswer) => userAnswer.answer.length === 0 && userAnswer.required
    )
    if (errors.length > 0) {
      setIsPollWrong(true)
      setSubmitted(true)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      dispatch(submitPoll(userAnswers, params.userIdentificator))
    }
  }

  const onQuit = () => {
    navigate(`/poll/quit`)
  }

  return (
    <div className='flex min-h-screen justify-center items-center p-10 bg-gray-200 relative'>
      {isModal && (
        <Modal
          onCancel={() => setIsModal(false)}
          onConfirm={() => onQuit()}
          message={t('POLL_SUBMISION.QUIT_MESSAGE')}
        />
      )}
      {getSubmisionDataLoading && <LoadingOverlay />}
      {submitPollLoading && <LoadingOverlay />}
      {poll && (
        <form
          onSubmit={onPollSubmision}
          className='flex flex-col w-full h-full max-w-[700px] bg-white sm:px-[40px] px-[20px] pt-[62px] pb-[40px] shadow'
        >
          {isPollWrong && submitted && (
            <div className='mb-7'>
              <ErrorAlert message={t('POLL_SUBMISION.ANSWER_ON_ALL_REQUIRED_QUESTIONS')} />
            </div>
          )}
          <div>
            <h3 className='text-2xl font-medium leading-6 text-gray-900'>{poll.name}</h3>
          </div>
          <div className='mt-8'>
            <h3 className='text-md font-medium leading-6 text-gray-900'>
              {t('POLL_SUBMISION.DESCRIPTION')}
            </h3>
            <p className='mt-1 text-sm text-gray-500'>{poll.description}</p>
          </div>
          <div className='mt-8'>
            <h3 className='text-md font-medium leading-6 text-gray-900'>
              {t('POLL_SUBMISION.INSTRUCTIONS')}
            </h3>
            <p className='mt-1 text-sm text-gray-500'>{poll.instructions}</p>
          </div>
          <div className='mt-10 flex flex-col gap-8'>
            {poll.questions.map((question, index) => {
              const error =
                userAnswers.length > 0
                  ? userAnswers.find(
                      (userAnswer) =>
                        userAnswer.questionId === question.id &&
                        userAnswer.answer.length === 0 &&
                        userAnswer.required
                    )
                  : false
              switch (question.answerType) {
                case 'checkBox':
                  return (
                    <CheckTypeForm
                      key={index}
                      question={question}
                      index={index}
                      submitted={submitted}
                      error={error}
                      setVal={setVal}
                    />
                  )
                case 'radio':
                  return (
                    <RadioTypeForm
                      key={index}
                      question={question}
                      index={index}
                      submitted={submitted}
                      error={error}
                      setVal={setVal}
                    />
                  )
                case 'text':
                  return (
                    <TextTypeForm
                      key={index}
                      question={question}
                      index={index}
                      submitted={submitted}
                      error={error}
                      setVal={setVal}
                    />
                  )
                default:
                  return null
              }
            })}
          </div>
          <div className='flex justify-start mt-5'>
            <SquareBtnColored
              text={t('POLL_SUBMISION.FINISH')}
              type='submit'
              onClick={onPollSubmision}
              style={{ marginRight: 5 }}
            />
            <SquareBtnTransparent
              text={t('POLL_SUBMISION.QUIT')}
              onClick={() => setIsModal(true)}
              type='button'
            />
          </div>
          <div className='mt-10 flex flex-col-reverse sm:flex-row items-center justify-between gap-3'>
            <img
              src={ESF_Lenta}
              alt='footer'
              className='w-full sm:w-[60%] relative -left-[20px] sm:-left-[40px]'
            />
            <img src={Logo} alt='Logo' className='w-14' />
          </div>
        </form>
      )}
    </div>
  )
}

export default PollSubmision

import {
  CLONE_POLL,
  CREATED_POLL_FAIL,
  CREATED_POLL_SUCCESS,
  DELETE_POLL_SUCCESS,
  EDITED_POLL_FAIL,
  EDITED_POLL_SUCCESS,
  FINISH_POLL_FAIL,
  FINISH_POLL_REQUEST,
  FINISH_POLL_SUCCESS,
  GET_ALL_POLLS,
  GET_ALL_POLLS_FAIL,
  GET_ALL_POLLS_REQUEST,
  GET_POLL,
  GET_POLL_FAIL,
  GET_POLL_SUCCESS,
  PUBLISH_POLL_FAIL,
  PUBLISH_POLL_REQUEST,
  PUBLISH_POLL_SUCCESS,
  RESET_EDIT_POLL,
  RESET_FINISH_DATA,
  RESET_GET_POLL,
  RESET_POLLS,
  RESET_POLL_DATA,
  RESET_PUBLISH_DATA,
  SUBMIT_CREATED_POLL,
  SUBMIT_EDITED_POLL
} from '../constants/pollConstants'

const initialState = []

export const crudPollsReducer = (polls = initialState, action) => {
  let error = ''
  switch (action.type) {
    case RESET_POLLS:
      return { loading: false, polls: [] }
    case GET_ALL_POLLS_REQUEST:
      return { loading: true }
    case GET_ALL_POLLS:
      if (polls.length === 0) {
        polls.push(action.payload)
      } else {
        polls = action.payload.allPolls
      }
      return { loading: false, polls: polls, count: action.payload.count }
    case GET_ALL_POLLS_FAIL:
      error = action.payload
      return { loading: false, error }
    case DELETE_POLL_SUCCESS:
      polls = polls.polls.filter((poll) => poll.id !== action.payload)
      return { loading: false, polls: polls, deleted: true }
    case CLONE_POLL:
      if (polls.polls) {
        polls = [...polls.polls, action.payload]
      }
      return { loading: false, polls: polls }
    default:
      return polls
  }
}

export const editPollReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case SUBMIT_EDITED_POLL:
      return { loading: true }
    case EDITED_POLL_SUCCESS:
      return { editLoading: false, edited: true }
    case RESET_EDIT_POLL:
      return { editLoading: false, edited: false }
    case EDITED_POLL_FAIL:
      error = action.payload
      return { loading: false, error }
    default:
      return state
  }
}

export const submitCreatedPollReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case SUBMIT_CREATED_POLL:
      return { loading: true }
    case CREATED_POLL_SUCCESS:
      return { loading: false, created: true }
    case CREATED_POLL_FAIL:
      error = action.payload
      return { loading: false, error }
    case RESET_POLL_DATA:
      return { loading: false, created: false }
    default:
      return state
  }
}

export const getPollByIdReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case GET_POLL_SUCCESS:
      return { loading: true }
    case GET_POLL:
      return { loading: false, fetchedOne: action.payload }
    case GET_POLL_FAIL:
      error = action.payload
      return { loading: false, error }
    case RESET_GET_POLL:
      return { loading: false, fetchedOne: {} }
    default:
      return state
  }
}

export const publishPollReducer = (state = {}, action) => {
  switch (action.type) {
    case PUBLISH_POLL_REQUEST:
      return { loading: true }
    case PUBLISH_POLL_SUCCESS:
      return { loading: false, success: action.payload, published: true }
    case PUBLISH_POLL_FAIL:
      return { loading: false, publishError: true }
    case RESET_PUBLISH_DATA:
      return { loading: false, success: false, published: false }
    default:
      return state
  }
}

export const finishPollReducer = (state = {}, action) => {
  switch (action.type) {
    case FINISH_POLL_REQUEST:
      return { loading: true }
    case FINISH_POLL_SUCCESS:
      return { loading: false, ended: true }
    case FINISH_POLL_FAIL:
      return { loading: false, finishedError: true }
    case RESET_FINISH_DATA:
      return { loading: false, ended: false }
    default:
      return state
  }
}

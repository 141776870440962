import {
  ArrowUpOnSquareIcon,
  CheckCircleIcon,
  DocumentDuplicateIcon,
  DocumentMagnifyingGlassIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { api } from '../../../../../services/api.service'

const ReactListTableRow = ({
  q,
  formatDate,
  onPollClone,
  onOpenDeleteModal,
  onOpenPublishModal,
  onOpenFinishModal,
  setPublishing
}) => {
  const [rectipientsNumbers, setRectipientsNumbers] = useState()
  const { t } = useTranslation()
  useEffect(() => {
    const getRecipientsNumbers = async () => {
      await api.get(`/polls/recipients-numbers/${q.id}`).then((res) => {
        setRectipientsNumbers(res.data)
      })
    }
    getRecipientsNumbers()
  }, [q.id])

  return (
    <tr>
      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
        <Link
          to={`${
            q.status === 'Unpublished' || q.status === 'In progress' ? '' : `/polls/results/${q.id}`
          }`}
        >
          {q.name}
        </Link>
      </td>
      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
        {q.publishDate ? formatDate(q.publishDate) : ''}
      </td>
      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
        {q.finishDate ? formatDate(q.finishDate) : ''}
      </td>
      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
        {q.status === 'Unpublished'
          ? t('POLL_LIST.UNPUBLISHED')
          : q.status === 'Published'
          ? t('POLL_LIST.PUBLISHED')
          : q.status === 'Finished'
          ? t('POLL_LIST.FINISHED')
          : q.status === 'In progress'
          ? t('POLL_LIST.IN_PROGRESS')
          : ''}
      </td>
      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
        {rectipientsNumbers && rectipientsNumbers.recipientsWhoCompleted}
      </td>
      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
        {rectipientsNumbers && rectipientsNumbers.sentToRecipients}
      </td>
      <td className='whitespace-nowrap text-sm text-gray-500 pl-3'>
        {rectipientsNumbers && rectipientsNumbers.recipientsFromTags}
      </td>
      <td className='whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium flex items-center justify-end'>
        {q.status === 'Unpublished' && (
          <>
            <button
              onClick={() => {
                setPublishing(true)
                onOpenPublishModal(q.id)
              }}
              data-tooltip={t('POLL_LIST.PUBLISH_POLL')}
            >
              <ArrowUpOnSquareIcon className='w-[18px] text-gray-900' />
            </button>
            <button onClick={() => onPollClone(q)} data-tooltip={t('POLL_LIST.CLONE_POLL')}>
              <DocumentDuplicateIcon className='w-[18px] text-gray-900 ml-2' />
            </button>
            <Link to={`/polls/edit/${q.id}`} data-tooltip={t('POLL_LIST.EDIT_POLL')}>
              <PencilSquareIcon className='w-[18px] text-gray-900 ml-2' />
            </Link>
            <button
              onClick={() => onOpenDeleteModal(q.id)}
              data-tooltip={t('POLL_LIST.DELETE_POLL')}
            >
              <TrashIcon className='w-[18px] text-gray-900 ml-2' />
            </button>
          </>
        )}
        {q.status === 'Published' && (
          <>
            <Link to={`/polls/results/${q.id}`} data-tooltip={t('POLL_LIST.POLL_PREVIEW')}>
              <DocumentMagnifyingGlassIcon className='w-[18px] text-gray-900' />
            </Link>
            <button onClick={() => onPollClone(q)} data-tooltip={t('POLL_LIST.CLONE_POLL')}>
              <DocumentDuplicateIcon className='w-[18px] text-gray-900 ml-2' />
            </button>
            <button
              onClick={() => onOpenFinishModal(q.id)}
              data-tooltip={t('POLL_LIST.FINISH_POLL')}
            >
              <CheckCircleIcon className='w-[18px] text-gray-900 ml-2'></CheckCircleIcon>
            </button>
          </>
        )}
        {q.status === 'Finished' && (
          <>
            <Link to={`/polls/results/${q.id}`} data-tooltip={t('POLL_LIST.POLL_PREVIEW')}>
              <DocumentMagnifyingGlassIcon className='w-[18px] text-gray-900' />
            </Link>
            <button onClick={() => onPollClone(q)} data-tooltip={t('POLL_LIST.CLONE_POLL')}>
              <DocumentDuplicateIcon className='w-[18px] text-gray-900 ml-2' />
            </button>
          </>
        )}
      </td>
    </tr>
  )
}

export default ReactListTableRow

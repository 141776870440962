import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ErrorAlert, SuccessAlert } from '../../../components/Alerts'
import { Input, SquareBtnColored, SquareBtnTransparent } from '../../../components/FormComponents'
import LoadingOverlay from '../../../components/LoadingOverlay'
import Modal from '../../../components/Modal'
import { createUser, getOneUser, updateUser } from '../../../redux/actions/userActions'
import {
  USER_CREATE_RESET,
  USER_GET_ONE_RESET,
  USER_UPDATE_RESET
} from '../../../redux/constants/userConstants'
import { validation } from '../../../services/validation/userForm'

export const UsersForm = () => {
  let errors = {}
  const [initialLoad, setInitialLoad] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [createCancelModal, setCreateCancelModal] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [inputs, setInputs] = useState({
    email: '',
    userName: '',
    password: '',
    repeatedPassword: ''
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { userId } = useParams()
  const location = useLocation()

  const { userCreate, userUpdate, userGetOne } = useSelector((state) => state)
  const {
    loading: loadingUserCreate,
    success: successUserCreate,
    error: errorUserCreate
  } = userCreate
  const {
    loading: loadingUserUpdate,
    success: successUserUpdate,
    error: errorUserUpdate
  } = userUpdate
  const { loading: loadingUserGetOne, error: errorUserGetOne, user } = userGetOne

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      dispatch(getOneUser(userId))
      setIsEdit(true)
    }
    setInitialLoad(true)
    dispatch({ type: USER_CREATE_RESET })
    dispatch({ type: USER_UPDATE_RESET })
  }, [dispatch, userId, location.pathname])

  useEffect(() => {
    if (successUserCreate || successUserUpdate) {
      setTimeout(() => {
        navigate('/users', { replace: true })
        dispatch({ type: USER_CREATE_RESET })
        dispatch({ type: USER_UPDATE_RESET })
      }, 1000)
    }
  }, [successUserCreate, successUserUpdate, dispatch, navigate])

  useEffect(() => {
    if (user && isEdit) {
      setInputs({
        userName: user.userName,
        email: user.email,
        password: '',
        repeatedPassword: ''
      })
    }
    if (errorUserGetOne && initialLoad) {
      dispatch({ type: USER_GET_ONE_RESET })
      setTimeout(() => {
        navigate('/users', { replace: true })
      }, 1000)
    }
  }, [user, errorUserGetOne, dispatch, initialLoad, isEdit, navigate])

  useEffect(() => {
    if (successUserCreate) {
      setInputs({
        userName: '',
        email: '',
        password: '',
        repeatedPassword: ''
      })
    }
    if (successUserUpdate) {
      setInputs({
        userName: inputs.userName,
        email: inputs.email,
        password: '',
        repeatedPassword: ''
      })
    }
    setSubmitted(false)
  }, [successUserCreate, successUserUpdate, inputs.userName, inputs.email])

  const setVal = (value, name) => {
    setInputs({ ...inputs, [name]: value })
  }

  const onCreate = (e) => {
    e.preventDefault()
    setSubmitted(true)
    errors = validation(inputs)
    if (!isEmpty(errors)) {
      return
    } else {
      dispatch(createUser(inputs))
    }
  }

  const onUpdate = (e) => {
    e.preventDefault()
    setSubmitted(true)
    errors = validation(inputs, isEdit)
    if (!isEmpty(errors)) {
      return
    } else {
      dispatch(updateUser(inputs, userId))
    }
  }

  if (submitted) {
    errors = validation(inputs, isEdit)
  }

  return (
    <div className='w-full'>
      {createCancelModal && (
        <Modal
          onConfirm={() => navigate('/users')}
          onCancel={() => setCreateCancelModal(false)}
          message={userId ? t('USERS.QUIT_EDIT_MESSAGE') : t('USERS.QUIT_MESSAGE')}
        />
      )}
      <form
        onSubmit={userId ? onUpdate : onCreate}
        className='max-w-[600px] mx-auto bg-white px-[40px] py-12 shadow relative'
      >
        {loadingUserCreate || loadingUserGetOne || (loadingUserUpdate && <LoadingOverlay />)}
        {successUserCreate && <SuccessAlert message={t('USERS.SUCCESSFULLY_CREATED_USER')} />}
        {successUserUpdate && <SuccessAlert message={t('USERS.SUCCESSFULLY_UPDATED_USER')} />}
        {errorUserCreate && <ErrorAlert message={t(errorUserCreate)} />}
        {errorUserUpdate && <ErrorAlert message={t(errorUserUpdate)} />}
        <div className='space-y-8 divide-y divide-gray-200'>
          <div className=''>
            <h1 className='text-[28px] font-semibold text-gray-900'>
              {userId ? t('USERS.EDIT_USER') : t('USERS.CREATE_USER')}
            </h1>
            <div className='mt-6'>
              <Input
                value={inputs['userName']}
                type='text'
                onChange={setVal}
                error={errors['userName']}
                name='userName'
                label={t('USERS.USER_NAME')}
              />
              <Input
                value={inputs['email']}
                type='text'
                onChange={setVal}
                error={errors['email']}
                name='email'
                label={t('USERS.EMAIL')}
              />
              <Input
                value={inputs['password']}
                type='password'
                onChange={setVal}
                error={errors['password']}
                name='password'
                label={t('USERS.PASSWORD')}
              />
              <div className='mt-3'>
                <div className='flex flex-col items-start text-sm'>
                  <h1 className='text-gray-700'>{t('VALIDATION.REQUIREMENTS')}</h1>
                  <ul className='list-disc list-inside text-gray-700'>
                    <li>{t('VALIDATION.PASSWORD_LENGTH')}</li>
                    <li>{t('VALIDATION.PASSWORD_ONE_LOWER')}</li>
                    <li>{t('VALIDATION.PASSWORD_ONE_CAPITAL')}</li>
                    <li>{t('VALIDATION.PASSWORD_ONE_SPECIAL')}</li>
                    <li>{t('VALIDATION.PASSWORD_ONE_NUMBER')}</li>
                  </ul>
                </div>
              </div>
              <Input
                value={inputs['repeatedPassword']}
                type='password'
                onChange={setVal}
                error={errors['repeatedPassword']}
                name='repeatedPassword'
                label={t('USERS.REPEATED_PASSWORD')}
              />
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <div className='flex justify-start'>
            {userId ? (
              <>
                <SquareBtnColored
                  onClick={onUpdate}
                  type='submit'
                  style={{ marginRight: 5 }}
                  text={t('USERS.UPDATE')}
                />
                <SquareBtnTransparent
                  onClick={() => setCreateCancelModal(true)}
                  text={t('USERS.QUIT')}
                />
              </>
            ) : (
              <>
                <SquareBtnColored
                  type='submit'
                  style={{ marginRight: 5 }}
                  text={t('USERS.CREATE')}
                  onClick={onCreate}
                />
                <SquareBtnTransparent
                  onClick={() => setCreateCancelModal(true)}
                  text={t('USERS.QUIT')}
                />
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}

export default UsersForm

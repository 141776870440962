import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

const PollActionsMessages = () => {
  const { t } = useTranslation()
  const params = useParams()
  const [actionMessages, setActionMessages] = useState({
    head: '',
    message: ''
  })

  useEffect(() => {
    switch (params.action) {
      case 'submitted':
        setActionMessages({
          head: t('POLL_SUBMISION.ACTION_MESSAGES.SUBMITTED.HEAD')
        })
        break
      case 'quit':
        setActionMessages({
          head: t('POLL_SUBMISION.ACTION_MESSAGES.QUIT.HEAD')
        })
        break
      case 'completed':
        setActionMessages({
          head: t('POLL_SUBMISION.ACTION_MESSAGES.COMPLETED.HEAD')
        })
        break
      case 'finished':
        setActionMessages({
          head: t('POLL_SUBMISION.ACTION_MESSAGES.FINISHED.HEAD')
        })
        break
      case 'in-progress':
        setActionMessages({
          head: t('POLL_SUBMISION.ACTION_MESSAGES.IN_PROGRESS.HEAD')
        })
        break
      default:
        break
    }
  }, [params.action, t])

  return (
    <div className='flex flex-col min-h-screen justify-center pb-[40px] pt-[20px] sm:px-6 lg:px-8 px-4 bg-gray-200'>
      <form className='w-full max-w-[700px] mx-auto bg-white px-[40px] pt-[44px] pb-[40px] shadow relative mb-40'>
        <div className='mt-1'>
          <h3 className='text-xl font-medium leading-6 text-gray-900'>{actionMessages.head}</h3>
        </div>
      </form>
    </div>
  )
}

export default PollActionsMessages

import { XMarkIcon } from '@heroicons/react/20/solid'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

const Modal = ({ message, onCancel, onConfirm }) => {
  const { t } = useTranslation()
  return (
    <div>
      <div
        className='fixed w-full h-full bg-white z-[52] left-0 top-0 bg-opacity-80'
        onClick={onCancel}
      ></div>
      <div className='rounded-lg shadow fixed bg-white z-[53] max-w-[400px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]'>
        <button
          onClick={onCancel}
          type='button'
          className='absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center'
        >
          <XMarkIcon className='w-5 h-5' />
        </button>
        <div className='p-6 text-center flex flex-col md:block items-center'>
          <ExclamationCircleIcon className='mx-auto mb-4 text-gray-400 w-14 h-14' />
          <h3 className='mb-5 text-lg font-normal text-gray-500'>{message}</h3>
          <div className='flex flex-col gap-2 md:flex-row justify-center'>
            <button
              onClick={onConfirm}
              type='button'
              className='text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2'
            >
              {t('MODAL.YES')}
            </button>
            <button
              type='button'
              onClick={onCancel}
              className='text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10'
            >
              {t('MODAL.NO')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal

import i18next from 'i18next'
import { Suspense } from 'react'
import { initReactI18next } from 'react-i18next'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import PositionsActionMessages from './components/Birthday_Poll/PositionsActionsMessages'
import PositionsPoll from './components/Birthday_Poll/PositionsPoll'
import PollActionsMessages from './components/Poll/PollActionsMessages'
import PollSubmision from './components/Poll/PollSubmision'
import { en, hr } from './helpers/translations'
import { AuthLayout, DashboardLayout } from './layouts'
import SignIn from './pages/Auth/SignIn'
import PollListTable from './pages/Dashboard/Polls/PollList/pollListTable'
import PollResults from './pages/Dashboard/Polls/PollResults/PollResults'
import PollResultsPDF from './pages/Dashboard/Polls/PollResults/PollsResultsPDF'
import CreatePollForm from './pages/Dashboard/Polls/components/createPollForm'
import EditPollForm from './pages/Dashboard/Polls/components/editPollForm'
import RecipientsForm from './pages/Dashboard/Recipients/RecipientForm'
import RecipientsList from './pages/Dashboard/Recipients/RecipientsList'
import RecipientsUploadFromFile from './pages/Dashboard/Recipients/RecipientsUploadFromFile'
import UsersForm from './pages/Dashboard/Users/UserForm'
import UsersList from './pages/Dashboard/Users/UsersList'

i18next.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    hr: { translation: hr }
  },
  lng: 'hr',
  fallBackLng: 'hr',
  interpolation: { escapeValue: false }
})

const App = () => {
  return (
    <div className=''>
      <Suspense fallback='Loading...'>
        <BrowserRouter>
          <Routes>
            <Route element={<DashboardLayout />}>
              <Route path='/polls' element={<PollListTable />} />
              <Route path='/polls/create-new' element={<CreatePollForm />} />
              <Route path='/polls/results/:id' element={<PollResults />} />
              <Route path='/polls/edit/:id' element={<EditPollForm />} />
              <Route path='/recipients/create-new' element={<RecipientsForm />} />
              <Route path='/recipients/edit/:recipientId' element={<RecipientsForm />} />
              <Route path='/recipients' element={<RecipientsList />} />
              <Route path='/recipients/upload-from-file' element={<RecipientsUploadFromFile />} />
              <Route path='/users' element={<UsersList />} />
              <Route path='/users/create-new' element={<UsersForm />} />
              <Route path='/users/edit/:userId' element={<UsersForm />} />
            </Route>
            <Route element={<AuthLayout />}>
              <Route path='/signin' element={<SignIn />} />
            </Route>
            <Route path='/polls/:pollName/:userIdentificator' element={<PollSubmision />} />
            <Route path='/positions/:id' element={<PositionsPoll />} />
            <Route path='/positions/finished' element={<PositionsActionMessages />} />
            <Route path='/poll/:action' element={<PollActionsMessages />} />
            <Route path='/polls/results/:id/download' element={<PollResultsPDF />} />
            <Route path='*' element={<Navigate to='/polls' replace />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  )
}

export default App

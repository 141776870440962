import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'

export const AuthLayout = () => {
  const userSignin = useSelector((state) => state.userSignin)
  const { isSignin } = userSignin

  const navigate = useNavigate()

  useEffect(() => {
    if (isSignin) {
      navigate('/polls', { replace: true })
    }
  }, [isSignin, navigate])

  return (
    <div className='flex min-h-screen flex-col justify-center bg-gray-200'>
      <Outlet />
    </div>
  )
}

export default AuthLayout

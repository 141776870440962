import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { getAccessToken } from '../services/token.service'
import { GLOBAL_RESET } from './constants/globalConstants'
import {
  crudPollsReducer,
  editPollReducer,
  finishPollReducer,
  getPollByIdReducer,
  publishPollReducer,
  submitCreatedPollReducer
} from './reducers/pollReducers'
import { pollResultsGetReducer } from './reducers/pollResultsReducers'
import {
  pollSubmisioSubmitReducer,
  pollSubmisionGetDataReducer
} from './reducers/pollSubmisionReducers'
import {
  positionsSubmisionGetDataReducer,
  positionsSubmisionSubmitReducer
} from './reducers/positionsReducers'
import {
  recipientCreateReducer,
  recipientDeleteReducer,
  recipientGetAllReducer,
  recipientGetOneReducer,
  recipientUpdateReducer,
  recipientUploadReducer
} from './reducers/recipientsReducers'
import { tagsCreateReducer, tagsGetAllReducer } from './reducers/tagsReducers'
import {
  userCreateReducer,
  userDeleteReducer,
  userGetAllReducer,
  userGetOneReducer,
  userSigninReducer,
  userUpdateReducer
} from './reducers/userReducers'

const initialState = {
  userSignin: {
    isSignin: !!getAccessToken() ? true : false
  }
}

const reducer = combineReducers({
  userSignin: userSigninReducer,
  userGetAll: userGetAllReducer,
  userDelete: userDeleteReducer,
  userCreate: userCreateReducer,
  userUpdate: userUpdateReducer,
  userGetOne: userGetOneReducer,
  recipientGetAll: recipientGetAllReducer,
  recipientDelete: recipientDeleteReducer,
  recipientCreate: recipientCreateReducer,
  recipientUpdate: recipientUpdateReducer,
  recipientGetOne: recipientGetOneReducer,
  pollSubmisionGetData: pollSubmisionGetDataReducer,
  pollSubmisioSubmit: pollSubmisioSubmitReducer,
  positionsSubmisionGetData: positionsSubmisionGetDataReducer,
  positionsSubmisionSubmit: positionsSubmisionSubmitReducer,
  pollResultsGet: pollResultsGetReducer,
  publishPoll: publishPollReducer,
  tagsGetAll: tagsGetAllReducer,
  tagsCreate: tagsCreateReducer,
  recipientUpload: recipientUploadReducer,
  polls: crudPollsReducer,
  singlePoll: getPollByIdReducer,
  isCreated: submitCreatedPollReducer,
  isEdited: editPollReducer,
  isFinished: finishPollReducer
})

const rootReducer = (state, action) => {
  if (action.type === GLOBAL_RESET) {
    state = undefined
  }

  return reducer(state, action)
}
const store = createStore(rootReducer, initialState, compose(applyMiddleware(thunk)))

export default store

import {
  USER_CREATE_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_RESET,
  USER_CREATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_RESET,
  USER_DELETE_SUCCESS,
  USER_GET_ALL_FAIL,
  USER_GET_ALL_REQUEST,
  USER_GET_ALL_SUCCESS,
  USER_GET_ONE_FAIL,
  USER_GET_ONE_REQUEST,
  USER_GET_ONE_RESET,
  USER_GET_ONE_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_RESET,
  USER_UPDATE_SUCCESS
} from '../constants/userConstants'

export const userSigninReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true }
    case USER_SIGNIN_SUCCESS:
      return { loading: false, isSignin: action.payload }
    case USER_SIGNIN_FAIL:
      switch (action.payload) {
        case 'USER_NOT_FOUND':
          error = 'SIGN_IN.USER_NOT_FOUND'
          break
        case 'WRONG_PASSWORD':
          error = 'SIGN_IN.WRONG_PASSWORD'
          break
        default:
          error = 'SIGN_IN.SOMETHING_WENT_WRONG'
      }
      return { loading: false, error }
    case USER_SIGNOUT_SUCCESS:
      return {}
    default:
      return state
  }
}

export const userGetAllReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case USER_GET_ALL_REQUEST:
      return { loading: true }
    case USER_GET_ALL_SUCCESS:
      return {
        loading: false,
        users: action.payload.users,
        count: action.payload.count
      }
    case USER_GET_ALL_FAIL:
      error = action.payload
      return { loading: false, error }
    default:
      return state
  }
}

export const userDeleteReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true }
    case USER_DELETE_SUCCESS:
      return { loading: false, success: action.payload }
    case USER_DELETE_FAIL:
      switch (action.payload) {
        case 'USER_DELETE_YOURSELF':
          error = 'USERS.USER_DELETE_YOURSELF'
          break
        default:
          error = 'USERS.SOMETHING_WENT_WRONG'
      }
      return { loading: false, error }
    case USER_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const userCreateReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return { loading: true }
    case USER_CREATE_SUCCESS:
      return { loading: false, success: true }
    case USER_CREATE_FAIL:
      switch (action.payload) {
        case 'USER_ALREADY_EXIST':
          error = 'USERS.USER_ALREADY_EXIST'
          break
        default:
          error = 'USERS.SOMETHING_WENT_WRONG'
      }
      return { loading: false, error }
    case USER_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const userUpdateReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true }
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case USER_UPDATE_FAIL:
      switch (action.payload) {
        case 'USER_ALREADY_EXIST':
          error = 'USERS.USER_ALREADY_EXIST'
          break
        default:
          error = 'USERS.SOMETHING_WENT_WRONG'
      }
      return { loading: false, error }
    case USER_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const userGetOneReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case USER_GET_ONE_REQUEST:
      return { loading: true }
    case USER_GET_ONE_SUCCESS:
      return { loading: false, success: true, user: action.payload }
    case USER_GET_ONE_FAIL:
      error = action.payload
      return { loading: false, error }
    case USER_GET_ONE_RESET:
      return {}
    default:
      return state
  }
}

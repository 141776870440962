export const RECIPIENT_GET_ALL_REQUEST = 'RECIPIENT_GET_ALL_REQUEST'
export const RECIPIENT_GET_ALL_SUCCESS = 'RECIPIENT_GET_ALL_SUCCESS'
export const RECIPIENT_GET_ALL_FAIL = 'RECIPIENT_GET_ALL_FAIL'

export const RECIPIENT_DELETE_REQUEST = 'RECIPIENT_DELETE_REQUEST'
export const RECIPIENT_DELETE_SUCCESS = 'RECIPIENT_DELETE_SUCCESS'
export const RECIPIENT_DELETE_FAIL = 'RECIPIENT_DELETE_FAIL'
export const RECIPIENT_DELETE_RESET = 'RECIPIENT_DELETE_RESET'

export const RECIPIENT_CREATE_REQUEST = 'RECIPIENT_CREATE_REQUEST'
export const RECIPIENT_CREATE_SUCCESS = 'RECIPIENT_CREATE_SUCCESS'
export const RECIPIENT_CREATE_FAIL = 'RECIPIENT_CREATE_FAIL'
export const RECIPIENT_CREATE_RESET = 'RECIPIENT_CREATE_RESET'

export const RECIPIENT_UPDATE_REQUEST = 'RECIPIENT_UPDATE_REQUEST'
export const RECIPIENT_UPDATE_SUCCESS = 'RECIPIENT_UPDATE_SUCCESS'
export const RECIPIENT_UPDATE_FAIL = 'RECIPIENT_UPDATE_FAIL'
export const RECIPIENT_UPDATE_RESET = 'RECIPIENT_UPDATE_RESET'

export const RECIPIENT_GET_ONE_REQUEST = 'RECIPIENT_GET_ONE_REQUEST'
export const RECIPIENT_GET_ONE_SUCCESS = 'RECIPIENT_GET_ONE_SUCCESS'
export const RECIPIENT_GET_ONE_FAIL = 'RECIPIENT_GET_ONE_FAIL'
export const RECIPIENT_GET_ONE_RESET = 'RECIPIENT_GET_ONE_RESET'

export const RECIPIENT_UPLOAD_REQUEST = 'RECIPIENT_UPLOAD_REQUEST'
export const RECIPIENT_UPLOAD_SUCCESS = 'RECIPIENT_UPLOAD_SUCCESS'
export const RECIPIENT_UPLOAD_FAIL = 'RECIPIENT_UPLOAD_FAIL'
export const RECIPIENT_UPLOAD_RESET = 'RECIPIENT_UPLOAD_RESET'

import { ArcElement, Chart, Legend, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Pie } from 'react-chartjs-2'

export const PieChart = ({ answers = [], results = [], sentTo }) => {
  Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels)
  const bgColors = ['#3566CD', '#10971A', '#0099C7', '#DA3814', '#FA9704', '#960198', '#3566CD']
  const brColors = ['#3566CD', '#10971A', '#0099C7', '#DA3814', '#FA9704', '#960198', '#3566CD']

  const data = {
    labels: answers,
    datasets: [
      {
        data: results,
        backgroundColor: bgColors,
        borderColor: brColors,
        borderWidth: 0
      }
    ]
  }

  const options = {
    responsive: true,
    layout: {
      padding: {
        left: 30,
        right: 30
      }
    },
    plugins: {
      legend: {
        display: false,
        position: 'top',
        labels: {
          font: {
            title: {
              size: 10
            }
          }
        }
      },
      datalabels: {
        color: 'black',
        labels: {
          title: {
            font: {
              size: 10
            }
          }
        },
        anchor: 'end',
        align: 'end',
        offset: -3,
        formatter: function (value) {
          return Math.round((value / sentTo) * 100) + '%' !== '0%'
            ? Math.round((value / sentTo) * 100) + '%'
            : ''
        }
      }
    }
  }

  return (
    <div className='w-full'>
      <Pie data={data} options={options} className='' />
    </div>
  )
}

export default PieChart

export const SUBMIT_CREATED_POLL = 'SUBMIT_CREATED_POLL'
export const CREATED_POLL_SUCCESS = 'CREATED_POLL_SUCCESS'
export const CREATED_POLL_FAIL = 'CREATED_POLL_FAIL'
export const RESET_POLL_DATA = 'RESET_POLL_DATA'
export const RESET_POLLS = 'RESET_POLLS'

export const SUBMIT_EDITED_POLL = 'SUBMIT_EDITED_POLL'
export const EDITED_POLL_SUCCESS = 'EDITED_POLL_SUCCESS'
export const EDITED_POLL_FAIL = 'EDITED_POLL_FAIL'
export const RESET_EDIT_POLL = 'RESET_EDIT_POLL'

export const DELETE_POLL = 'DELETE_POLL'
export const DELETE_POLL_SUCCESS = 'DELETE_POLL_SUCCESS'
export const DELETE_POLL_FAIL = 'DELETE_POLL_FAIL'

export const GET_ALL_POLLS_REQUEST = 'GET_ALL_POLLS_REQUEST'
export const GET_ALL_POLLS = 'GET_ALL_POLLS'
export const GET_ALL_POLLS_SUCCESS = 'GET_ALL_POLLS_SUCCESS'
export const GET_ALL_POLLS_FAIL = 'GET_ALL_POLLS_FAIL'

export const GET_POLL = 'GET_POLL'
export const GET_POLL_SUCCESS = 'GET_POLL_SUCCESS'
export const GET_POLL_FAIL = 'GET_POLL_FAIL'
export const CLONE_POLL = 'CLONE_POLL'
export const EDIT_POLL = 'EDIT_POLL'
export const RESET_GET_POLL = 'RESET_GET_POLL'

export const PUBLISH_POLL_REQUEST = 'PUBLISH_POLL_REQUEST'
export const PUBLISH_POLL_SUCCESS = 'PUBLISH_POLL_SUCCESS'
export const PUBLISH_POLL_FAIL = 'PUBLISH_POLL_FAIL'
export const RESET_PUBLISH_DATA = 'RESET_PUBLISH_DATA'

export const FINISH_POLL_REQUEST = 'FINISH_POLL_REQUEST'
export const FINISH_POLL_SUCCESS = 'FINISH_POLL_SUCCESS'
export const FINISH_POLL_FAIL = 'FINISH_POLL_FAIL'
export const FINISH_PUBLISH_DATA = 'FINISH_PUBLISH_DATA'
export const RESET_FINISH_DATA = 'RESET_PUBLISH_DATA'

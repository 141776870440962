import { HomeIcon } from '@heroicons/react/20/solid'
import { Bars3Icon } from '@heroicons/react/24/outline'
import capitalize from 'lodash/fp/capitalize'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Breadcrumb = ({ location, onClick }) => {
  const { t } = useTranslation()
  function generateBreadcrumbs() {
    const asPathNestedRoutes = location.pathname.split('/').filter((v) => v.length > 0)
    const crumblist = asPathNestedRoutes.map((subpath, idx) => {
      const path = '/' + asPathNestedRoutes.slice(0, idx + 1).join('/')
      const name = capitalize(subpath.replaceAll('-', '_'))
      return { path, name }
    })
    if (
      crumblist[crumblist.length - 2] &&
      (crumblist[crumblist.length - 2].name === 'Edit' ||
        crumblist[crumblist.length - 2].name === 'Results')
    ) {
      crumblist[crumblist.length - 2].path = crumblist[crumblist.length - 1].path
      crumblist.pop()
    }
    return crumblist
  }
  const breadcrumbs = generateBreadcrumbs()
  return (
    <nav className='sticky flex justify-between items-center top-0 border-b border-gray-200 bg-white shadow z-[50]'>
      <button onClick={onClick} className='pl-8 open-sidenav' type='button'>
        <Bars3Icon className='w-5 h-5' />
      </button>
      <ul className='flex w-full max-w-screen-xl space-x-4 px-8 h-[63px]'>
        <li className='flex'>
          <div className='flex items-center'>
            <Link to='/polls' className='text-gray-400 hover:text-gray-500'>
              <HomeIcon className='h-5 w-5 flex-shrink-0' aria-hidden='true' />
              <span className='sr-only'>Home</span>
            </Link>
          </div>
        </li>
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index} className='flex'>
            <div className='flex items-center'>
              <svg
                className='h-full w-6 flex-shrink-0 text-gray-200'
                viewBox='0 0 24 44'
                preserveAspectRatio='none'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
              >
                <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
              </svg>
              <Link
                to={breadcrumb.path}
                className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
              >
                {t(`BREADCRUMBS.${breadcrumb.name}`)}
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Breadcrumb

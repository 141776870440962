import i18n from './i18n'

export const isRequired = (value) => {
  if (value.length === 0) {
    return i18n.t('VALIDATION.REQUIRED')
  }
}

export const emailValidation = (value) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  if (!regex.test(value)) {
    return i18n.t('VALIDATION.EMAIL')
  }
}

export const passwordValidation = (value) => {
  if (value.length < 8) {
    return i18n.t('VALIDATION.PASSWORD_LENGTH')
  }
  if (value.search(/[a-z]/) === -1) {
    return i18n.t('VALIDATION.PASSWORD_ONE_LOWER')
  }
  if (value.search(/[A-Z]/) === -1) {
    return i18n.t('VALIDATION.PASSWORD_ONE_CAPITAL')
  }
  if (value.search(/[0-9]/) === -1) {
    return i18n.t('VALIDATION.PASSWORD_ONE_NUMBER')
  }
  if (value.search(/[-!$%^&*()_+|~=`'"\\{}\[\]:\/;<>?,.@#]/) === -1) {
    return i18n.t('VALIDATION.PASSWORD_ONE_SPECIAL')
  }
}

export const repeatedPasswordValidation = (value, repeatedValue) => {
  if (value !== repeatedValue) {
    return i18n.t('VALIDATION.REPEATED_PASSWORD')
  }
}

export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST'
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS'
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL'

export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS'
export const USER_SIGNOUT_FAIL = 'USER_SIGNOUT_FAIL'

export const USER_GET_ALL_REQUEST = 'USER_GET_ALL_REQUEST'
export const USER_GET_ALL_SUCCESS = 'USER_GET_ALL_SUCCESS'
export const USER_GET_ALL_FAIL = 'USER_GET_ALL_FAIL'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'
export const USER_DELETE_RESET = 'USER_DELETE_RESET'

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST'
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL'
export const USER_CREATE_RESET = 'USER_CREATE_RESET'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_GET_ONE_REQUEST = 'USER_GET_ONE_REQUEST'
export const USER_GET_ONE_SUCCESS = 'USER_GET_ONE_SUCCESS'
export const USER_GET_ONE_FAIL = 'USER_GET_ONE_FAIL'
export const USER_GET_ONE_RESET = 'USER_GET_ONE_RESET'

import {
  SUBMISION_GET_DATA_FAIL,
  SUBMISION_GET_DATA_REQUEST,
  SUBMISION_GET_DATA_SUCCESS,
  SUBMISION_SUBMIT_FAIL,
  SUBMISION_SUBMIT_REQUEST,
  SUBMISION_SUBMIT_SUCCESS
} from '../constants/pollSubmisionConstants'

export const pollSubmisionGetDataReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBMISION_GET_DATA_REQUEST:
      return { loading: true }
    case SUBMISION_GET_DATA_SUCCESS:
      return {
        loading: false,
        success: true,
        poll: action.payload.poll,
        recipient: action.payload.recipient
      }
    case SUBMISION_GET_DATA_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const pollSubmisioSubmitReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBMISION_SUBMIT_REQUEST:
      return { loading: true }
    case SUBMISION_SUBMIT_SUCCESS:
      return { loading: false, success: true }
    case SUBMISION_SUBMIT_FAIL:
      let error = ''
      return { loading: false, error }
    default:
      return state
  }
}

import { omit } from 'lodash'
import {
  emailValidation,
  isRequired,
  passwordValidation,
  repeatedPasswordValidation
} from './index'

export const validation = (inputs, isEdit = false) => {
  let errors = {}
  for (const [key, value] of Object.entries(inputs)) {
    switch (key) {
      case 'userName':
        errors[key] = isRequired(value)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'email':
        errors[key] = isRequired(value) || emailValidation(value)
        if (!errors[key]) {
          errors = omit(errors, key)
        }
        break
      case 'password':
        if (!isEdit || (isEdit && value.length > 0)) {
          errors[key] = isRequired(value) || passwordValidation(value)
          if (!errors[key]) {
            errors = omit(errors, key)
          }
        }
        if (isEdit && value.length === 0) {
          errors = omit(errors, key)
        }
        break
      case 'repeatedPassword':
        if (!isEdit || (isEdit && inputs['password'].length > 0)) {
          errors[key] = isRequired(value) || repeatedPasswordValidation(inputs['password'], value)
          if (!errors[key]) {
            errors = omit(errors, key)
          }
        }
        if (isEdit && inputs['password'].length === 0) {
          errors = omit(errors, key)
        }
        break
    }
  }
  return errors
}

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

export const BarChart = ({ answers = [], results = [], sentTo }) => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

  const bgColors = ['#3566CD', '#10971A', '#0099C7', '#DA3814', '#FA9704', '#960198', '#3566CD']
  const brColors = ['#3566CD', '#10971A', '#0099C7', '#DA3814', '#FA9704', '#960198', '#3566CD']

  const data = {
    labels: answers,
    datasets: [
      {
        data: results,
        backgroundColor: bgColors,
        borderColor: brColors,
        borderWidth: 0
      }
    ]
  }

  const options = {
    responsive: true,
    data: data,
    scales: {
      x: {
        ticks: {
          beginAtZero: true,
          color: 'rgb(97 97 97)',
          font: {
            size: 10
          }
        }
      },
      y: {
        ticks: {
          stepSize: 1,
          beginAtZero: true,
          color: 'rgb(97 97 97)',
          font: {
            size: 10
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false,
        align: 'start',
        labels: {
          font: {
            title: {
              size: 10
            }
          }
        }
      },
      datalabels: {
        color: 'white',
        labels: {
          title: {
            font: {
              size: 10
            }
          }
        },
        formatter: function (value, context) {
          const percent = Math.round((value / sentTo) * 100)
          return percent + '%'
        }
      }
    }
  }

  return (
    <div className='w-full'>
      <Bar data={data} width={300} options={options} className='h-full' />
    </div>
  )
}

export default BarChart

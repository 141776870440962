import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Logo from '../../../src/assets/images/logo.png'
import ESF_Lenta from '../../assets/images/banner.png'
import LoadingOverlay from '../../components/LoadingOverlay'
import Modal from '../../components/Modal'
import WarningModal from '../../components/WarningModal'
import {
  getPositionData,
  getRecipientData,
  submitPosition,
  unsubscribeGraduate,
  updatePosition
} from '../../redux/actions/positionsActions'
import { PositionsDatePickerInput } from '../FormComponents'
import EducationPosition from './components/EducationPosition'
import Input from './components/Input'
import WorkPosition from './components/WorkPosition'

const PositionsPoll = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()

  const [isSubscribed, setIsSubscribed] = useState()
  const [isWorkEditMode, setIsWorkEditMode] = useState(false)
  const [isEducationEditMode, setIsEducationEditMode] = useState(false)
  const [positions, setPositions] = useState([])
  const [warning, setWarning] = useState(false)
  const [warningMessage, setWarningMessage] = useState('')
  const [isDispatched, setIsDispatched] = useState(false)
  const [isEducationDataDispatched, setIsEducationDataDispatched] = useState(false)
  const [isWorkDataDispatched, setIsWorkDataDispatched] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [editedRecordIndex, setEditedRecordIndex] = useState(null)
  const [educationData, setEducationData] = useState({
    id: '',
    institution: '',
    major: '',
    graduated: false,
    startEducationDate: '',
    endEducationDate: ''
  })
  const [workData, setWorkData] = useState({
    id: '',
    company: '',
    position: '',
    finished: false,
    startWorkDate: '',
    endWorkDate: ''
  })
  const { positionsSubmisionGetData } = useSelector((state) => state)
  const { loading: loadingPosition, position, error: errorPosition } = positionsSubmisionGetData

  useEffect(() => {
    if (params.id) {
      dispatch(getPositionData(params.id))
    }
  }, [dispatch, params.id])

  useEffect(() => {
    if (position) {
      setPositions(position.positions)
    }
  }, [position])

  useEffect(() => {
    ;(async () => {
      if (params.id && position) {
        try {
          const recipient = await getRecipientData(position.tokenData.recipientId, params.id)
          if (recipient) {
            setIsSubscribed(recipient.unsubscribed)
          }
        } catch (error) {
          console.error('Error fetching recipient data:', error)
        }
      }
    })()
  }, [dispatch, params.id, position])

  useEffect(() => {
    if (isEducationDataDispatched) {
      setIsEducationDataDispatched(false)
      setIsDispatched(true)
      setEducationData({
        id: '',
        institution: '',
        major: '',
        graduated: false,
        startEducationDate: '',
        endEducationDate: ''
      })
    }
    if (isWorkDataDispatched) {
      setIsWorkDataDispatched(false)
      setIsDispatched(true)
      setWorkData({
        id: '',
        company: '',
        position: '',
        finished: false,
        startWorkDate: '',
        endWorkDate: ''
      })
    }
  }, [isEducationDataDispatched, isWorkDataDispatched, dispatch, params.id])

  useEffect(() => {
    if (isDispatched) {
      dispatch(getPositionData(params.id))
      setIsDispatched(false)
    }
  }, [isDispatched, dispatch, params.id])

  useEffect(() => {
    if (educationData.startEducationDate && educationData.endEducationDate) {
      if (
        new Date(educationData.startEducationDate).getFullYear() >
        new Date(educationData.endEducationDate).getFullYear()
      ) {
        setWarningMessage(t('POSITIONS.DATES_NOT_VALID'))
        setWarning(true)
        setEducationData((prevState) => ({
          ...prevState,
          endEducationDate: ''
        }))
      }
    }
  }, [isEducationEditMode, educationData])

  useEffect(() => {
    if (workData.startWorkDate && workData.endWorkDate) {
      if (
        new Date(workData.startWorkDate).getFullYear() >
        new Date(workData.endWorkDate).getFullYear()
      ) {
        setWarningMessage(t('POSITIONS.DATES_NOT_VALID'))
        setWorkData((prevState) => ({
          ...prevState,
          endWorkDate: ''
        }))
        setWarning(true)
      }
    }
  }, [isWorkEditMode, workData])

  const setVal = (value, name) => {
    switch (name) {
      case 'startEducationDate':
        setEducationData((prevState) => ({
          ...prevState,
          startEducationDate: value
        }))
        break
      case 'endEducationDate':
        setEducationData((prevState) => ({
          ...prevState,
          endEducationDate: value
        }))
        break
      case 'startWorkDate':
        setWorkData((prevState) => ({
          ...prevState,
          startWorkDate: value
        }))
        break
      case 'endWorkDate':
        setWorkData((prevState) => ({
          ...prevState,
          endWorkDate: value
        }))
        break
      default:
        break
    }
  }

  const onQuit = () => {
    navigate(`/positions/finished`, { replace: true })
  }

  const updateGraduated = (index) => {
    const tempPositions = [...positions?.educations]
    tempPositions[index] = {
      ...tempPositions[index],
      graduated: !tempPositions[index].graduated,
      endEducationDate: !tempPositions[index].graduated ? new Date() : null
    }
    setEducationData((prevState) => ({
      ...prevState,
      graduated: !prevState.graduated,
      endEducationDate: !prevState.graduated ? new Date() : null
    }))
  }

  const updateFinished = (index) => {
    const tempPositions = [...positions?.jobs]
    tempPositions[index] = {
      ...tempPositions[index],
      finished: !tempPositions[index].finished,
      endWorkDate: !tempPositions[index].finished ? new Date() : null
    }
    setWorkData((prevState) => ({
      ...prevState,
      finished: !prevState.finished,
      endWorkDate: !prevState.finished ? new Date() : null
    }))
  }

  const unsubscribe = (recipientId, token, value) => {
    unsubscribeGraduate(recipientId, token, value)
  }

  const addEducationDataAndSend = (e) => {
    e.preventDefault()
    if (isEducationEditMode) {
      for (const [key, value] of Object.entries(educationData)) {
        if (value === '' && key !== 'endEducationDate') {
          setWarningMessage(t('POSITIONS.EMPTY_FIELDS'))
          setWarning(true)
          return
        }
      }
    } else {
      if (!educationData.institution || !educationData.major) {
        setWarningMessage(t('POSITIONS.EMPTY_FIELDS'))
        setWarning(true)
        return
      }
    }

    const data = {
      type: 'education',
      recipientId: position.tokenData.recipientId,
      educationData,
      id: educationData.id
    }

    if (isEducationEditMode) {
      dispatch(updatePosition(data, params.id))
      setIsEducationDataDispatched(true)
      setIsWorkDataDispatched(false)
      setWarning(false)
      setWarningMessage('')
      setIsEducationEditMode(false)
      return
    } else {
      dispatch(submitPosition(data, params.id))
      setIsEducationDataDispatched(true)
      setIsWorkDataDispatched(false)
      setWarning(false)
      setWarningMessage('')
      setIsEducationEditMode(false)
      return
    }
  }

  const addWorkDataAndSend = (e) => {
    e.preventDefault()
    if (isWorkEditMode) {
      for (const [key, value] of Object.entries(workData)) {
        if (value === '' && key !== 'endWorkDate') {
          setWarningMessage(t('POSITIONS.EMPTY_FIELDS'))
          setWarning(true)
          return
        }
      }
    } else {
      if (!workData.company || !workData.position) {
        setWarningMessage(t('POSITIONS.EMPTY_FIELDS'))
        setWarning(true)
        return
      }
    }

    const data = {
      type: 'work',
      recipientId: position.tokenData.recipientId,
      workData,
      id: workData.id
    }

    if (isWorkEditMode) {
      dispatch(updatePosition(data, params.id))
      setIsEducationDataDispatched(false)
      setIsWorkDataDispatched(true)
      setWarning(false)
      setWarningMessage('')
      setIsWorkEditMode(false)
      return
    } else {
      dispatch(submitPosition(data, params.id))
      setIsEducationDataDispatched(false)
      setIsWorkDataDispatched(true)
      setWarning(false)
      setWarningMessage('')
      setIsWorkEditMode(false)
      return
    }
  }

  const openModal = (e) => {
    e.preventDefault()
    setIsFinished(true)
  }

  return (
    <div className='flex min-h-screen justify-center items-center p-10 bg-gray-200 relative'>
      {isFinished && (
        <Modal
          message={t('POSITIONS.FINISH_POSITIONS_REQUEST')}
          onCancel={() => setIsFinished(false)}
          onConfirm={() => onQuit()}
        />
      )}
      {errorPosition && <LoadingOverlay />}
      {warning && (
        <WarningModal
          message={warningMessage}
          onCancel={() => setWarning(false)}
          onConfirm={() => setWarning(false)}
        />
      )}
      {loadingPosition ? (
        <LoadingOverlay />
      ) : (
        positions && (
          <form className='w-full max-w-[700px] h-full bg-white sm:px-[40px] px-[20px] pt-[62px] pb-[40px] shadow'>
            <div className='flex flex-col flex-wrap justify-center'>
              <h1 className='text-3xl font-medium leading-6 text-gray-900'>
                {t('POSITIONS.YEARLY_POLL_NAME')}
              </h1>
              <p className='mt-3 text-sm text-gray-600'>{t('POSITIONS.HEADER_MESSAGE')}</p>
            </div>
            <div className='flex flex-col mt-3 gap-10'>
              <div className='flex flex-col gap-5'>
                <h1 className='text-2xl font-medium text-gray-900 mt-3'>
                  {t('POSITIONS.EDUCATION', { year: new Date().getFullYear() })}
                </h1>
                <div className='flex flex-col-reverse gap-5'>
                  <div className='flex flex-col gap-2'>
                    {positions?.educations?.map((pos, index) => (
                      <EducationPosition
                        key={index}
                        index={index}
                        position={pos}
                        isEducationEditMode={isEducationEditMode}
                        updateGraduated={updateGraduated}
                        editedRecordIndex={editedRecordIndex}
                        setEducationData={setEducationData}
                        setIsEducationEditMode={setIsEducationEditMode}
                        setEditedRecordIndex={setEditedRecordIndex}
                      />
                    ))}
                  </div>
                  <div className='flex gap-3'>
                    <Input
                      width={'w-1/2'}
                      placeholder={t('POSITIONS.EDUCATION_PLACEHOLDER')}
                      value={educationData?.institution}
                      onChange={(e) => {
                        setEducationData((prevState) => ({
                          ...prevState,
                          institution: e.target.value
                        }))
                      }}
                    />
                    <Input
                      width={'w-1/2'}
                      placeholder={t('POSITIONS.EDUCATION_PLACEHOLDER_2')}
                      value={educationData?.major}
                      onChange={(e) => {
                        setEducationData((prevState) => ({
                          ...prevState,
                          major: e.target.value
                        }))
                      }}
                    />
                    {(positions?.educations?.length == 0 || isEducationEditMode) && (
                      <>
                        <PositionsDatePickerInput
                          onChange={setVal}
                          name='startEducationDate'
                          label={t('POSITIONS.FROM')}
                          value={educationData?.startEducationDate}
                        />
                        <PositionsDatePickerInput
                          onChange={setVal}
                          name='endEducationDate'
                          label={t('POSITIONS.TO')}
                          value={educationData?.endEducationDate}
                        />
                      </>
                    )}
                    <button
                      className='ml-auto rounded-md border border-transparent bg-indigo-600 py-2 px-4 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      onClick={addEducationDataAndSend}
                    >
                      {isEducationEditMode ? t('POSITIONS.SAVE') : t('POSITIONS.ADD')}
                    </button>
                    {isEducationEditMode && educationData && (
                      <button
                        className='ml-auto rounded-md border border-transparent bg-indigo-600 py-2 px-4 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        onClick={() => {
                          setIsEducationEditMode(false)
                          setEducationData({
                            id: '',
                            institution: '',
                            major: '',
                            graduated: false,
                            startEducationDate: '',
                            endEducationDate: ''
                          })
                        }}
                      >
                        {t('POSITIONS.CANCEL')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className='flex flex-col gap-5'>
                <h1 className='text-2xl font-medium text-gray-900 mt-3'>
                  {t('POSITIONS.WORK', { year: new Date().getFullYear() })}
                </h1>
                <div className='flex flex-col-reverse gap-5'>
                  <div className='flex flex-col gap-2'>
                    {positions?.jobs?.map((pos, index) => (
                      <WorkPosition
                        key={index}
                        index={index}
                        position={pos}
                        isWorkEditMode={isWorkEditMode}
                        updateFinished={updateFinished}
                        editedRecordIndex={editedRecordIndex}
                        setWorkData={setWorkData}
                        setIsWorkEditMode={setIsWorkEditMode}
                        setEditedRecordIndex={setEditedRecordIndex}
                      />
                    ))}
                  </div>
                  <div className='flex gap-3'>
                    <Input
                      width={'w-1/2'}
                      placeholder={t('POSITIONS.WORK_PLACEHOLDER')}
                      value={workData.company}
                      onChange={(e) => {
                        setWorkData((prevState) => ({
                          ...prevState,
                          company: e.target.value
                        }))
                      }}
                    />
                    <Input
                      width={'w-1/2'}
                      placeholder={t('POSITIONS.WORK_PLACEHOLDER_2')}
                      value={workData.position}
                      onChange={(e) => {
                        setWorkData((prevState) => ({
                          ...prevState,
                          position: e.target.value
                        }))
                      }}
                    />
                    {(positions?.jobs?.length == 0 || isWorkEditMode) && (
                      <>
                        <PositionsDatePickerInput
                          onChange={setVal}
                          name='startWorkDate'
                          label={t('POSITIONS.FROM')}
                          value={workData?.startWorkDate}
                        />
                        <PositionsDatePickerInput
                          onChange={setVal}
                          name='endWorkDate'
                          label={t('POSITIONS.TO')}
                          value={workData?.endWorkDate}
                        />
                      </>
                    )}
                    <button
                      className='ml-auto rounded-md border border-transparent bg-indigo-600 py-2 px-4 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      onClick={addWorkDataAndSend}
                    >
                      {isWorkEditMode ? t('POSITIONS.SAVE') : t('POSITIONS.ADD')}
                    </button>
                    {isWorkEditMode && workData && (
                      <button
                        className='ml-auto rounded-md border border-transparent bg-indigo-600 py-2 px-4 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        onClick={() => {
                          setIsWorkEditMode(false)
                          setWorkData({
                            id: '',
                            company: '',
                            position: '',
                            finished: false,
                            startWorkDate: '',
                            endWorkDate: ''
                          })
                        }}
                      >
                        {t('POSITIONS.CANCEL')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className='flex flex-col-reverse'>
                <div className='flex justify-start items-center gap-2 mt-5'>
                  <input
                    type='checkbox'
                    className='form-checkbox text-indigo-600'
                    onClick={() =>
                      unsubscribe(position.tokenData.recipientId, params.id, !isSubscribed)
                    }
                    defaultChecked={isSubscribed}
                  />
                  <p className='text-gray-500 text-xs'>{t('POSITIONS.UNSUBSCRIBE')}</p>
                </div>
                <div className='flex flex-col gap-2'>
                  <button
                    className='rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    onClick={(e) => openModal(e)}
                  >
                    {t('POSITIONS.FINISH')}
                  </button>
                  <button
                    className='rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    onClick={(e) => openModal(e)}
                  >
                    {t('POSITIONS.NO_CHANGES')}
                  </button>
                </div>
              </div>
              <div className='mt-auto flex flex-col-reverse sm:flex-row items-center justify-between gap-3'>
                <img
                  src={ESF_Lenta}
                  alt='footer'
                  className='w-full sm:w-[60%] relative -left-[20px] sm:-left-[40px]'
                />
                <img src={Logo} alt='Logo' className='w-14' />
              </div>
            </div>
          </form>
        )
      )}
    </div>
  )
}

export default PositionsPoll

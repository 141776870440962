import { api } from '../../services/api.service'
import {
  TAGS_CREATE_FAIL,
  TAGS_CREATE_REQUEST,
  TAGS_CREATE_SUCCESS,
  TAGS_GET_ALL_FAIL,
  TAGS_GET_ALL_SUCCESS
} from '../constants/tagsConstants'

export const getAll = (tagName) => async (dispatch) => {
  await api
    .get(`/tag`, { params: { tag: tagName } })
    .then((res) => {
      const selectOptions = res.data.tags.map((tag) => {
        return { value: tag.id, label: tag.name }
      })
      dispatch({ type: TAGS_GET_ALL_SUCCESS, payload: selectOptions })
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: TAGS_GET_ALL_FAIL, payload: err.response.data.message })
    })
}

export const createTag =
  (tagName = '') =>
  async (dispatch) => {
    dispatch({ type: TAGS_CREATE_REQUEST, payload: {} })
    await api
      .post(`/tag`, { name: tagName })
      .then((res) => {
        dispatch({ type: TAGS_CREATE_SUCCESS, payload: res.data.tag })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: TAGS_CREATE_FAIL,
          payload: err.response.data.message
        })
      })
  }

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Modal from '../../../components/Modal'
import { deleteUser, getAll } from '../../../redux/actions/userActions'
import { USER_DELETE_RESET } from '../../../redux/constants/userConstants'
import { UsersTable } from './components'

export const UsersList = () => {
  const [isModal, setIsModal] = useState(false)
  const [userId, setUserId] = useState(null)
  const { userDelete } = useSelector((state) => state)
  const { success: successDelete } = userDelete
  const [userQuery, setUserQuery] = useState('')
  const [offset, setOffset] = useState(0)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch({ type: USER_DELETE_RESET })
  }, [dispatch])

  const onOpenModal = (id) => {
    setIsModal(true)
    setUserId(id)
  }

  const onDelete = () => {
    dispatch(deleteUser(userId))
    setOffset(0)
    setUserQuery('')
    setIsModal(false)
  }

  useEffect(() => {
    if (successDelete) {
      dispatch(getAll(0, ''))
      setUserQuery('')
    }
  }, [successDelete, dispatch])

  return (
    <div className='w-full self-start'>
      {isModal && (
        <Modal
          onConfirm={onDelete}
          onCancel={() => setIsModal(false)}
          message={t('USERS.DELETE_MESSAGE')}
        />
      )}
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-[28px] font-semibold text-gray-900'>{t('USERS.USERS_HEAD')}</h1>
        </div>
      </div>
      <div className='flex flex-row-reverse justify-between items-end gap-4 flex-wrap'>
        <div className='mt-4 flex gap-4 flex-wrap'>
          <Link
            to='/users/create-new'
            className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
          >
            {t('USERS.ADD_NEW')}
          </Link>
        </div>
        <div className='w-full max-w-[250px]'>
          <div className='mt-1 relative'>
            <div className='absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none'>
              <MagnifyingGlassIcon className='h-4 w-4 text-gray-400' aria-hidden='true' />
            </div>
            <input
              onChange={(e) => setUserQuery(e.target.value)}
              placeholder={t('USERS.SEARCH_USERS')}
              type='text'
              className='block w-full appearance-none rounded-md border border-gray-300 px-8 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm'
            />
          </div>
        </div>
      </div>
      <UsersTable
        offset={offset}
        setOffset={setOffset}
        onOpenModal={onOpenModal}
        userQuery={userQuery}
      />
    </div>
  )
}

export default UsersList

import {
  POLL_RESULTS_GET_FAIL,
  POLL_RESULTS_GET_REQUEST,
  POLL_RESULTS_GET_SUCCESS
} from '../constants/pollResultsConstants'

export const pollResultsGetReducer = (state = {}, action) => {
  let error = ''
  switch (action.type) {
    case POLL_RESULTS_GET_REQUEST:
      return { loading: true }
    case POLL_RESULTS_GET_SUCCESS:
      return { loading: false, poll: action.payload }
    case POLL_RESULTS_GET_FAIL:
      error = action.payload
      return { loading: false, error }
    default:
      return state
  }
}

import { api } from '../../services/api.service'
import {
  SUBMISION_GET_DATA_FAIL,
  SUBMISION_GET_DATA_REQUEST,
  SUBMISION_GET_DATA_SUCCESS,
  SUBMISION_SUBMIT_FAIL,
  SUBMISION_SUBMIT_REQUEST,
  SUBMISION_SUBMIT_SUCCESS
} from '../constants/pollSubmisionConstants'

export const getSubmisionData = (token) => async (dispatch) => {
  dispatch({ type: SUBMISION_GET_DATA_REQUEST, payload: {} })
  await api
    .get('/poll-submision', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => {
      dispatch({ type: SUBMISION_GET_DATA_SUCCESS, payload: res.data })
    })
    .catch((err) => {
      dispatch({
        type: SUBMISION_GET_DATA_FAIL,
        payload: err.response.data.message
      })
    })
}

export const submitPoll = (data, token) => async (dispatch) => {
  dispatch({ type: SUBMISION_SUBMIT_REQUEST, payload: {} })
  const answers = data.map((item) => {
    return {
      questionId: item.questionId,
      pollId: item.pollId,
      answer: item.answer
    }
  })
  await api
    .post(
      '/poll-submision',
      { answers },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then((res) => {
      dispatch({ type: SUBMISION_SUBMIT_SUCCESS, payload: res.data.tag })
    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: SUBMISION_SUBMIT_FAIL,
        payload: err.response.data.message
      })
    })
}

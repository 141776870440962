import { api } from '../../services/api.service'
import {
  POLL_RESULTS_GET_FAIL,
  POLL_RESULTS_GET_REQUEST,
  POLL_RESULTS_GET_SUCCESS
} from '../constants/pollResultsConstants'

export const getPollResults = (id) => async (dispatch) => {
  dispatch({ type: POLL_RESULTS_GET_REQUEST })
  await api
    .get(`/polls/${id}`)
    .then((res) => {
      dispatch({ type: POLL_RESULTS_GET_SUCCESS, payload: res.data })
    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: POLL_RESULTS_GET_FAIL,
        payload: err.response.data.message
      })
    })
}

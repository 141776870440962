import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { SuccessAlert } from '../../../../components/Alerts'
import Modal from '../../../../components/Modal'
import Pagination from '../../../../components/Pagination'
import WarningModal from '../../../../components/WarningModal'
import {
  crudPolls,
  finishPoll,
  publishPoll,
  resetFinishData,
  resetPublishData
} from '../../../../redux/actions/pollActions'
import {
  CLONE_POLL,
  DELETE_POLL,
  GET_ALL_POLLS,
  GET_POLL,
  RESET_POLL_DATA
} from '../../../../redux/constants/pollConstants'
import ReactListTableRow from './components/PollListTableRow'
export default function PollListTable() {
  const [offset, setOffset] = useState(0)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [pollId, setPollId] = useState(null)
  const [pollQuery, setPollQuery] = useState('')
  const [publishing, setPublishing] = useState(false)
  const [finishing, setFinishing] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(crudPolls(RESET_POLL_DATA))
    dispatch(resetPublishData())
    dispatch(resetFinishData())

    return () => {
      dispatch(crudPolls(RESET_POLL_DATA))
      dispatch(resetPublishData())
      dispatch(resetFinishData())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(crudPolls(GET_ALL_POLLS, { offset, queryString: pollQuery }))
  }, [pollQuery, offset, dispatch])

  const fetchedPolls = useSelector((state) => state.polls)
  const publishedPoll = useSelector((state) => state.publishPoll)
  const finishedPoll = useSelector((state) => state.isFinished)
  const fetchedPoll = useSelector((state) => state.singlePoll)
  const [isDonePublishing, setIsDonePublishing] = useState(false)

  const { published } = publishedPoll
  const { ended } = finishedPoll
  const { fetchedOne } = fetchedPoll
  const { loading, polls, count, deleted } = fetchedPolls

  const formatDate = (d) => {
    return moment(d).format('DD.MM.YYYY.')
  }

  useEffect(() => {
    if (published && isDonePublishing) {
      dispatch(crudPolls(GET_ALL_POLLS, { offset, queryString: pollQuery }))
      setIsDonePublishing(false)
    }
  }, [published, isDonePublishing])

  useEffect(() => {
    if (!loading && published === true) {
      dispatch(crudPolls(GET_ALL_POLLS, { offset, queryString: pollQuery }))
    }
  }, [published])

  useEffect(() => {
    if (fetchedOne && fetchedOne?.tags?.length > 0 && publishing) {
      setPollId(fetchedOne.id)
    } else if (fetchedOne && fetchedOne?.tags?.length === 0) {
      setShowWarningModal(true)
    }
  }, [fetchedOne])

  const onOpenDeleteModal = (id) => {
    setIsDeleteModal(true)
    setPollId(id)
  }

  const onPollDelete = () => {
    dispatch(crudPolls(DELETE_POLL, pollId))
    setIsDeleteModal(false)
  }

  const onPollClone = (poll) => {
    poll.questions.map((q) => {
      Reflect.deleteProperty(q, 'pollId')
      Reflect.deleteProperty(q, 'id')
      Reflect.deleteProperty(q, 'createdAt')
      Reflect.deleteProperty(q, 'updatedAt')
    })
    poll.tagsIds = poll.tags.map((tag) => tag.id)
    dispatch(crudPolls(CLONE_POLL, poll))
  }

  const onPublish = () => {
    dispatch(publishPoll(pollId))
    setPublishing(false)
    setIsDonePublishing(true)
    setTimeout(() => {
      dispatch(crudPolls(GET_ALL_POLLS, { offset, queryString: pollQuery }))
    }, 1000)
  }

  const onOpenPublishModal = (id) => {
    dispatch(crudPolls(GET_POLL, id))
  }

  const onOpenFinishModal = (id) => {
    setFinishing(true)
    setPollId(id)
  }

  const onFinish = () => {
    dispatch(finishPoll(pollId))
    setFinishing(false)
    dispatch(crudPolls(GET_ALL_POLLS, { offset, queryString: pollQuery }))
    dispatch(resetPublishData())
    dispatch(resetFinishData())
  }

  return (
    <div className='w-full self-start'>
      {isDeleteModal && (
        <Modal
          onConfirm={() => onPollDelete()}
          onCancel={() => setIsDeleteModal(false)}
          message={t('POLL_LIST.DELETE_POLL_REQUEST')}
        />
      )}
      {publishing && (
        <Modal
          onConfirm={() => onPublish()}
          onCancel={() => setPublishing(false)}
          message={t('POLL_LIST.PUBLISH_POLL_REQUEST')}
        />
      )}
      {finishing && (
        <Modal
          onConfirm={() => onFinish()}
          onCancel={() => setFinishing(false)}
          message={t('POLL_LIST.FINISH_POLL_REQUEST')}
        />
      )}
      {showWarningModal && (
        <WarningModal
          onCancel={() => setShowWarningModal(false)}
          message={t('POLL_LIST.NO_RECIPIENTS')}
        />
      )}
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-[28px] font-semibold text-gray-900'>{t('POLLS.POLLS_HEAD')}</h1>
        </div>
      </div>
      <div>
        <div className='flex flex-row-reverse items-end justify-between flex-wrap gap-4'>
          <div className='mt-4'>
            <Link
              to='/polls/create-new'
              className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
            >
              {t('POLLS.POLLS_CREATE_NEW')}
            </Link>
          </div>
          <div className='w-full max-w-[250px]'>
            <div className='mt-1 relative'>
              <div className='absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none'>
                <MagnifyingGlassIcon className='h-4 w-4 text-gray-400' aria-hidden='true' />
              </div>
              <input
                onChange={(e) => setPollQuery(e.target.value)}
                placeholder={t('RECIPIENTS.SEARCH_POLLS')}
                type='text'
                className='block w-full appearance-none rounded-md border border-gray-300 px-8 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm'
              />
            </div>
          </div>
        </div>
        {published && (
          <div style={{ marginTop: 10 }}>
            <SuccessAlert message={t('POLL_LIST.PUBLISH_POLL_SUCCESS')} />
          </div>
        )}
        {ended && (
          <div style={{ marginTop: 10 }}>
            <SuccessAlert message={t('POLL_LIST.FINISH_POLL_SUCCESS')} />
          </div>
        )}
        {deleted && (
          <div style={{ marginTop: 10 }}>
            <SuccessAlert message={t('POLL_LIST.DELETE_POLL_SUCCESS')} />
          </div>
        )}
        {!loading && polls && (
          <div className='mt-8 flex flex-col'>
            <div className='overflow-x-auto rounded-lg'>
              <div className='inline-block min-w-full align-middle'>
                <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg relative'>
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50'>
                      <tr>
                        {[
                          t('POLLS.POLL_NAME'),
                          t('POLLS.PUBLICATION_DATE'),
                          t('POLLS.END_DATE'),
                          t('POLLS.STATUS'),
                          t('POLLS.PARTICIPATED'),
                          t('POLLS.NUMBER_OF_RECIPIENTS'),
                          t('POLLS.TOTAL_RECIPIENTS'),
                          ''
                        ].map((item, index) => {
                          return (
                            <th
                              key={index}
                              scope='col'
                              className='p-3 text-left text-sm font-semibold text-gray-900'
                            >
                              {item}
                            </th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {polls &&
                        polls.length > 0 &&
                        polls.map((q, index) => {
                          return (
                            <ReactListTableRow
                              key={index}
                              q={q}
                              formatDate={formatDate}
                              onPollClone={onPollClone}
                              onOpenFinishModal={onOpenFinishModal}
                              onOpenDeleteModal={onOpenDeleteModal}
                              onOpenPublishModal={onOpenPublishModal}
                              setPublishing={setPublishing}
                            />
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Pagination count={count} onChange={setOffset} offset={offset} />
    </div>
  )
}
